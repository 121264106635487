import Section from '../ui-kit/section';

import '../../styles/components/auth.scss';
import ChangePassword from './ChangePassword';

export default function ChangePasswordContainer() {
  return (
    <Section className="Auth" classAsideName="wrapper" aside={false}>
      <ChangePassword />
    </Section>
  );
}
