import React, { useState } from 'react';
import Header from '../ui-kit/header';
import Input from '../ui-kit/input';
import Button from '../ui-kit/button';
import PropTypes from 'prop-types';
import { authenticate } from '../../helpers/api.services';
import { isEmpty } from 'lodash';
import { NotificationManager } from 'react-notifications';
import { useReduxDispatch } from '../../hooks/redux.hook';
import { authenticationActions } from '../../store/slices/authentication.slice';
import '../../styles/components/login.scss';
import { useTranslation } from 'react-i18next';

function Login({ formData }) {
  const dispatch = useReduxDispatch();
  const { t } = useTranslation();
  const { set: setToken } = authenticationActions;
  const [data, setData] = useState(formData);
  const [fetch, setFetch] = useState(false);
  const [error, setError] = useState();
  const isSubmitEnabled =
    Boolean(!isEmpty(data?.username) && !isEmpty(data?.password)) && !fetch;

  function onInputChangeHandler(id, value) {
    setData(state => ({
      ...state,
      [id]: value,
    }));
  }
  async function onSubmitHandler() {
    setError(null);
    setFetch(true);

    let response;
    try {
      response = await authenticate(data);
    } catch (exception) {
      !exception?.data &&
        exception?.message &&
        NotificationManager.error(exception.message);
      setError(exception);
    }

    if (response?.data?.access_token) {
      dispatch(setToken(response.data.access_token));
    }

    setFetch(false);
  }

  return (
    <div className="Login">
      <Header text={t('LoginPage.header')} size={Header.sizes.middle} />
      <Input
        id="username"
        label={t('LoginPage.login.label')}
        value={data?.username}
        placeholder={t('LoginPage.login.placeholder')}
        onChange={onInputChangeHandler}
        error={error?.data?.username}
      />
      <Input
        id="password"
        label={t('LoginPage.password.label')}
        value={data?.password}
        placeholder={t('LoginPage.password.placeholder')}
        type="password"
        error={error?.data?.password}
        onChange={onInputChangeHandler}
      />
      <Button
        disabled={!isSubmitEnabled}
        className="sign-in"
        label={t('LoginPage.submitButton')}
        onClick={onSubmitHandler}
      />
    </div>
  );
}
Login.defaultProps = {
  formData: {
    username: 'root@user',
    password: '12345678',
  },
};
Login.propTypes = {
  formData: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }),
  onForgotPassword: PropTypes.func,
};

export default Login;
