import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_UA } from '@ag-grid-community/locale';
import classNames from 'classnames';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { fetchLogFileThunk } from '../../../../store/logs/logsOperations';
import {
  selectLogContent,
  selectLogLoading,
  selectSelectedFile,
} from '../../../../store/logs/logsSelectors';

import {
  applyColumnsVisibility,
  initializeVisibleCols,
  parseLogContent,
} from './utils/logViewerUtils';
import { getLogViewerColumns } from './utils/logViewerColumns';
import { getRowClassByLogLevel } from './utils/getRowClassByLogLevel';
import { getSelectableColumns } from './utils/selectableColumns';

import Spinner from '../../../ui-kit/spinner';
import { QuickFilter } from '../QuickFilter';
import { ColumnVisibilityToggler } from './ColumnVisibilityToggler';

import { LogEntry } from '../../../../types/intarfaces';

export const LogViewer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedFile = useAppSelector(selectSelectedFile);
  const rawLogContent = useAppSelector(selectLogContent);
  const loading = useAppSelector(selectLogLoading);

  const [rowData, setRowData] = useState<LogEntry[]>([]);
  const [quickFilter, setQuickFilter] = useState('');
  const gridApiRef = useRef<GridApi | null>(null);
  const columnDefs = useMemo(() => getLogViewerColumns(), []);
  const selectableColumns = useMemo(() => getSelectableColumns(), []);
  const [visibleCols, setVisibleCols] = useState(() =>
    initializeVisibleCols(selectableColumns)
  );

  const ensureDomOrder = true;

  useEffect(() => {
    if (selectedFile) {
      dispatch(fetchLogFileThunk(selectedFile));
    }
  }, [dispatch, selectedFile]);

  useEffect(() => {
    if (!rawLogContent) {
      setRowData([]);
      return;
    }

    const parsed = parseLogContent(rawLogContent);
    setRowData(parsed);
  }, [rawLogContent]);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      gridApiRef.current = params.api;
      applyColumnsVisibility(params.api, visibleCols);
    },
    [visibleCols]
  );

  if (loading) {
    return <Spinner label="Завантаження вмісту лога..." />;
  }

  return (
    <>
      {!selectedFile ? (
        <p className="logViewerEmptyState">{t('Logs.noFileSelected')}</p>
      ) : (
        <div>
          <QuickFilter
            label={`${t('Logs.labelViewerLogs')}: `}
            gridApiRef={gridApiRef}
            value={quickFilter}
            setValue={setQuickFilter}
            placeholder={t('Logs.quickSearchViewerLogsPl')}
          />

          <ColumnVisibilityToggler
            columns={selectableColumns}
            visibleCols={visibleCols}
            setVisibleCols={setVisibleCols}
            gridApiRef={gridApiRef}
          />

          <div className={classNames('ag-theme-quartz', 'LogsTable')}>
            <AgGridReact
              ensureDomOrder={ensureDomOrder}
              getRowClass={params =>
                getRowClassByLogLevel(params.data?.logLevel)
              }
              localeText={AG_GRID_LOCALE_UA}
              columnDefs={columnDefs}
              rowData={rowData}
              onGridReady={onGridReady}
              pagination
              paginationPageSize={50}
              quickFilterText={quickFilter}
              enableCellTextSelection={true}
            />
          </div>
        </div>
      )}
    </>
  );
};
