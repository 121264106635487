import { useState } from 'react';
import { TError, TEvent } from '../types';
import Logger from '../helpers/logger';
import { verifyEmployees } from '../helpers/api.services';
import { IFRAME_EVENTS } from '../dto';
import useIFramesMessengerHook from './useIFramesMessenger.hook';
import { getSettings } from '../data/settings';
import { isUndefined } from 'lodash';
import CrossMessages, {
  TCrossMessagesData,
  TCrossMessagesOptions,
} from '../helpers/cross-messages';
import { getCompaniesEmployees } from '../helpers/cross-messages.services';

export type TCompanyUsersHookState = {
  fetching: boolean;
  fetched: boolean;
  data: any[] | undefined;
  error: TError | null;
};
export type TCompaniesHook = {
  state: TCompanyUsersHookState;
  verified: TCompanyUsersHookState;
  prepare: Function;
  reset: Function;
  verify: Function;

  isStateLoading: boolean;
  isStateReady: boolean;
  isVerificationLoading: boolean;
  isVerificationReady: boolean;
};

const defaultState: TCompanyUsersHookState = {
  fetching: false,
  fetched: false,
  data: undefined,
  error: null,
};
export default function useCompanyUsersHook(): TCompaniesHook {
  const [state, setState] = useState<TCompanyUsersHookState>(defaultState);
  const [verified, setVerified] =
    useState<TCompanyUsersHookState>(defaultState);

  const onEvent = (event: TEvent) => {
    if (event.type === IFRAME_EVENTS.GET_COMPANY_EMPLOYERS) {
      const error = event.data?.error;
      const state_ = !error
        ? {
            data: event.data || [],
            error: null,
            fetching: false,
            fetched: true,
          }
        : {
            error,
            data: [],
            fetching: false,
            fetched: true,
          };

      setState(state_);
    }
  };
  const options: TCrossMessagesOptions = {
    namespace: getSettings().iFrameNamespace,
    client: CrossMessages.CLIENT_TYPES.CHILD,
    debugEnabled: true,
    onEvent,
  };
  const { send } = useIFramesMessengerHook(options);

  function reset() {
    setState(defaultState);
    setVerified(defaultState);
  }
  async function prepare(props: any) {
    reset();
    setState(_ => ({ ..._, fetching: true }));

    const { data } = <TCrossMessagesData>await getCompaniesEmployees(props);

    setState(_ => ({ ..._, data, fetching: false, fetched: true }));
  }

  async function verify(company_id: number, user_ids: number[]) {
    /* debug */ Logger.saga(
      'useCompanyUsersHook() verify([ company_id, user_ids ])',
      { company_id, user_ids }
    );

    setVerified(_ => ({ ...defaultState, fetching: true }));

    let payload: any, error: any;

    try {
      payload = await verifyEmployees(company_id, user_ids);
    } catch (exception: any) {
      const message =
        exception?.message || '[ useCompanyUsersHook ] unhandled error';
      error = { message };
      /* error */ Logger.error('useCompanyUsersHook.load([ error ])', {
        error,
      });
    }

    !error &&
      Logger.success('useCompanyUsersHook() verify([ data ])', payload?.data);

    !error
      ? setVerified(_state => ({ ..._state, data: payload?.data, error: null }))
      : setVerified(_state => ({ ..._state, data: [], error }));

    setVerified(_state => ({ ..._state, fetched: true, fetching: false }));
  }

  const isStateLoading = !state.fetched && state.fetching;
  const isStateReady = !Boolean(
    isStateLoading || state.error || isUndefined(state.data)
  );
  const isVerificationLoading = !verified.fetched && verified.fetching;
  const isVerificationReady = !Boolean(
    isVerificationLoading || verified.error || isUndefined(verified.data)
  );

  return {
    isVerificationLoading,
    isVerificationReady,
    verified,
    verify,

    isStateLoading,
    isStateReady,
    state,
    prepare,

    reset,
  };
}
