import React, { FC } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { t } from 'i18next';
import { fetchLogFileBlob } from '../../../../../../helpers/api.services';

interface DownloadCellRendererProps {
  data: { filename: string };
  context: { apiHostUrl: string };
}

export const DownloadCellRenderer: FC<DownloadCellRendererProps> = ({
  data,
  context,
}) => {
  const handleDownload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      const blob = await fetchLogFileBlob(data.filename, context.apiHostUrl);

      const blobUrl = URL.createObjectURL(blob);
      const tempLink = document.createElement('a');
      tempLink.href = blobUrl;
      tempLink.download = data.filename;
      tempLink.click();
      URL.revokeObjectURL(blobUrl);
    } catch (err) {
      console.error('Ошибка при скачивании файла:', err);
    }
  };

  return (
    <button
      type="button"
      onClick={handleDownload}
      aria-label={t('FilesExplorer.tooltips.download') + ' ' + data.filename}
    >
      <Tooltip placement="top" title={t('FilesExplorer.tooltips.download')}>
        <DownloadOutlined className="download" />
      </Tooltip>
    </button>
  );
};
