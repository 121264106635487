import React, { useState } from 'react';
import MomentJS from 'moment';
import Logger from '../../../../../helpers/logger';
import Button from '../../../../../components/ui-kit/button';
import Label from '../../../../../components/ui-kit/label';
import { DATE_TIME_FORMAT } from '../../../../../data/constants';
import {
  formatSize,
  parseFileName,
  shortenString,
} from '../../../../../helpers/common';
import { Input, Modal, Tooltip } from 'antd';
import { get } from 'lodash';
import {
  CheckOutlined,
  CloseCircleFilled,
  DeleteFilled,
  EditFilled,
  HourglassFilled,
  InfoCircleFilled,
  WarningFilled,
} from '@ant-design/icons';
import CommentCellView from './Comment.CellView';
import SignCellView from './Sign.CellView';
import { TModalHandler } from '../../../../../types';
import FileInfo from '../FileInfo';
import StatusCellView from './StatusID.CellView';
import DownloadFileLink from '../../../../ui-kit/download-file-link';
import classNames from 'classnames';

export enum FilesExplorerColumns {
  name = 'name',
  size = 'size',
  status_id = 'status_id',
  _sign = '$sign',
  _comment = '$comment',
  _owner = '$owner',
  _toolbar = '$toolbar',
}
export type TCellRendererWrapperProps = {
  id: string;
  value: string;
  key: number;
  data: any;
  index: number;
  children?: any;
};

type TFilesExplorerCellRendererParams = {
  t: Function;
  isOwner: boolean;
  isReadOnly: boolean;
  disableSignersSelection: boolean;
  disableFileStatusSelection: boolean;
  company_id: number;
  onSignersUpdate: Function;
  onRemove: Function;
  onRemoveLocalFile: Function;
  onChangeFileName: Function;
  onChangeComment: Function;
  onChangeFileStatus: Function;
};

declare global {
  interface File {
    updatedAt?: string;
    createdAt?: string;
  }
}
type TFilesCellProps = {
  file: File;
  onUpdate: Function;
};

function CellRendererWrapper(props: TCellRendererWrapperProps) {
  return (
    <span key={`${props.id}-${props.index}`} data-field-id={props.id}>
      {props?.children}
    </span>
  );
}
function factory(params: TFilesExplorerCellRendererParams) {
  /* debug */ Logger.warn(
    'CellRenderer([ TFilesExplorerCellRendererParams ])',
    params
  );

  const {
    t,
    disableSignersSelection,
    disableFileStatusSelection,
    isReadOnly,
    isOwner,
    company_id,
    onSignersUpdate,
    onChangeFileName,
    onChangeComment,
    onRemoveLocalFile,
    onChangeFileStatus,
  } = params;
  return {
    [FilesExplorerColumns.name]: (_params: TCellRendererWrapperProps): any => {
      const file = _params.data;
      const isLocalFile = File.prototype.isPrototypeOf(file);

      /* debug */ Logger.warn('CellRenderer([ file ])', file);

      function LocalFileCell(props: TFilesCellProps) {
        const { file, onUpdate } = props;
        const name = get(file, 'altName');
        const disabled = get(file, 'disabled');
        const filename = parseFileName(name);
        const [edit, setEdit] = useState(false);
        const [newName, setNewName] = useState(filename.name);

        const lastModified = MomentJS(file.lastModified).format(
          DATE_TIME_FORMAT
        );
        const size = formatSize(file.size);

        const limit = 64;
        const value = `${filename.name || ''}`.trim();
        const shortName = shortenString(value, limit, '...');
        const isLengthChanged = shortName.length < value.length;

        const onChangeFilenameHandler = (event: any) => {
          setNewName(event?.target?.value);
        };
        const onClose = () => {
          setEdit(false);
        };
        const onOpen = () => {
          setEdit(true);
        };
        const onSave = () => {
          onUpdate && onUpdate(file, newName, filename.extension);
          onClose();
        };

        const context = (text: string) => (
          <b className="name">
            {disabled ? (
              <HourglassFilled spin />
            ) : (
              <EditFilled className="edit" onClick={onOpen} />
            )}
            {text}
          </b>
        );

        return (
          <div className="LocalFileCell">
            {!edit ? (
              <div className="title">
                {!isLengthChanged ? (
                  context(value)
                ) : (
                  <Tooltip placement="top" title={name}>
                    {context(shortName)}
                  </Tooltip>
                )}
                {filename.extension && (
                  <b className="extension">.{filename.extension}</b>
                )}
              </div>
            ) : (
              <Input
                rootClassName="title-editor"
                placeholder={name}
                value={newName}
                suffix={
                  <div className="title-editor-actions">
                    <CheckOutlined className="save" onClick={onSave} />
                    <CloseCircleFilled className="cancel" onClick={onClose} />
                  </div>
                }
                onChange={onChangeFilenameHandler}
              />
            )}
          </div>
        );
      }
      function RemoveFileCell(props: TFilesCellProps) {
        const { file } = props;
        const name = file.name;
        const filename = parseFileName(name);
        const lastModified = MomentJS(file.createdAt).format(DATE_TIME_FORMAT);
        const size = formatSize(file.size);
        const limit = 64;
        const value = `${filename.name || ''}`.trim();
        const shortName = shortenString(value, limit, '...');
        const isLengthChanged = shortName.length < value.length;
        const context = (text: string) => <b className="name">{text}</b>;

        return (
          <div className="RemoteFileCell">
            <div className="title">
              {!isLengthChanged ? (
                context(value)
              ) : (
                <Tooltip placement="top" title={name}>
                  {context(shortName)}
                </Tooltip>
              )}
              {filename.extension && (
                <b className="extension">.{filename.extension}</b>
              )}
            </div>
            <div className="footer">
              <b>{`${t('MyTickets.columns.addedAt')}:`}</b>
              &nbsp;
              {`${lastModified}`}
            </div>
          </div>
        );
      }

      const CellRendererComponent = isLocalFile
        ? LocalFileCell
        : RemoveFileCell;

      if (_params.data.error) return <b className="error">{file.name}</b>;

      return (
        <CellRendererWrapper {..._params}>
          <CellRendererComponent
            file={_params.data}
            onUpdate={onChangeFileName}
          />
        </CellRendererWrapper>
      );
    },
    [FilesExplorerColumns._comment]: (
      _params: TCellRendererWrapperProps
    ): any => {
      if (_params.data.error) return null;

      const value = `${_params?.data?.comment || ''}`.trim();
      const file = _params.data;
      const isLocalFile = File.prototype.isPrototypeOf(file);

      function onUpdate(value: string) {
        onChangeComment && onChangeComment(_params.data, value);
      }
      return (
        <CellRendererWrapper {..._params}>
          <CommentCellView
            readonly={!isLocalFile}
            value={value}
            onUpdate={onUpdate}
          />
        </CellRendererWrapper>
      );
    },
    [FilesExplorerColumns._sign]: (_params: TCellRendererWrapperProps): any => {
      return (
        <CellRendererWrapper {..._params}>
          <SignCellView
            isOwner={isOwner}
            isReadOnly={isReadOnly || disableSignersSelection}
            file={_params.data}
            company_id={company_id}
            onSelect={onSignersUpdate}
          />
        </CellRendererWrapper>
      );
    },
    [FilesExplorerColumns.size]: (_params: TCellRendererWrapperProps): any => {
      const file = _params.data;
      const size = formatSize(file.size);
      const isError = Boolean(_params?.data?.error);

      return (
        <CellRendererWrapper {..._params}>
          <b className={classNames(isError ? 'error' : '')}>{`${size.size} ${t(
            `common.dataSizes.${size.type}`
          )}`}</b>
        </CellRendererWrapper>
      );
    },
    [FilesExplorerColumns.status_id]: (
      _params: TCellRendererWrapperProps
    ): any => {
      /* debug */ Logger.log(
        'TFilesExplorerCellRenderer([ _params, params ])',
        { _params, params }
      );

      if (!_params.data.status_id) return;
      if (_params.data.error) {
        return (
          <b className="error">
            <WarningFilled />
            &nbsp;{_params.data.error.message}
          </b>
        );
      }

      const file = _params.data;
      const isLocalFile = File.prototype.isPrototypeOf(file);

      function onChangeFileStatusHandler(status: object) {
        onChangeFileStatus({ ...file, ...status });
      }
      return (
        <CellRendererWrapper {..._params}>
          {!isLocalFile ? (
            <StatusCellView
              disabled={disableFileStatusSelection}
              data={_params.data}
              onChange={data => onChangeFileStatusHandler(data)}
            />
          ) : null}
        </CellRendererWrapper>
      );
    },
    [FilesExplorerColumns._owner]: (params: TCellRendererWrapperProps): any => {
      if (params.data.error) return;

      const owner = params.data?.$owner;
      if (!owner) return;

      const position = owner?.position;
      const fullname = [
        `${owner?.userFirstName || ''}`.trim(),
        `${owner?.userMiddleName || ''}`.trim(),
        `${owner?.userLastName || ''}`.trim(),
      ]
        .join(' ')
        .trim();

      /* debug */ Logger.log('[ _owner ]', owner, { position, fullname });

      return (
        <CellRendererWrapper {...params}>
          <Label.WithHeader value={fullname} placeholder={position} />
        </CellRendererWrapper>
      );
    },
    [FilesExplorerColumns._toolbar]: (
      params: TCellRendererWrapperProps
    ): any => {
      const file = params.data;
      const isLocalFile = File.prototype.isPrototypeOf(file);

      function onRemove() {
        function onYes(handler: TModalHandler) {
          onRemoveLocalFile && onRemoveLocalFile(file);
          handler.destroy();
        }
        function onClose(handler: TModalHandler) {
          handler.destroy();
        }

        const handler: TModalHandler = Modal.confirm({
          title: t('common.confirmation'),
          content: t('prompts.removeFile', { filename: file.altName }),
          footer: (
            <span className="ant-modal-footer">
              <Button
                key="yes"
                className="save"
                label={t('common.yes')}
                onClick={() => onYes(handler)}
              />
              <Button
                key="cancel"
                ghost
                className="cancel"
                label={t('common.cancel')}
                onClick={() => onClose(handler)}
              />
            </span>
          ),
        });
      }
      function onShowFileInfo() {
        /* debug */ Logger.log('onShowFileInfo([ params ])', params);
        Modal.info({
          title: t('common.document'),
          content: <FileInfo file={file} />,
          closable: true,
          footer: null,
        });
      }

      if (isLocalFile) {
        return (
          <CellRendererWrapper {...params}>
            <div className="actions">
              <Tooltip placement="top" title={t('common.remove')}>
                <DeleteFilled className="delete" onClick={onRemove} />
              </Tooltip>
            </div>
          </CellRendererWrapper>
        );
      }

      return (
        <CellRendererWrapper {...params}>
          <div className="actions">
            {!isLocalFile && <DownloadFileLink remoteFile={file} />}
            <Tooltip placement="top" title={t('FilesExplorer.tooltips.info')}>
              <InfoCircleFilled className="info" onClick={onShowFileInfo} />
            </Tooltip>
            {/*
                        <Tooltip placement="top" title={ t("FilesExplorer.tooltips.view") }>
                            <a href={ fileUrl } target="_blank" download={ file.name }>
                                <EyeFilled className="view"  />
                            </a>
                        </Tooltip>

                        */}
          </div>
        </CellRendererWrapper>
      );
    },
  };
}

export default factory;
