import { useTranslation } from 'react-i18next';
import { RedoOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import classNames from 'classnames';

import Header from '../../../ui-kit/header';
import PropTypes from 'prop-types';

function Toolbar(props) {
  const { t } = useTranslation();
  const { onRefresh } = props;
  return (
    <div className="Toolbar">
      <div className="left">
        <Header text={t('Logs.header')} size={Header.sizes.middle} />
      </div>
      <div className="right">
        <Tooltip placement="top" title={t('common.refresh')}>
          <div
            className={classNames('Button', 'RefreshButton')}
            onClick={onRefresh}
          >
            <RedoOutlined />
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

Toolbar.propTypes = {
  onRefresh: PropTypes.func,
};

export default Toolbar;
