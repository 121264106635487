import { useEffect, useState, useRef, useCallback } from 'react';
import useSession from '../../hooks/useSession.hook';
import { useSelector } from 'react-redux';

const IdleLogout = () => {
  const [isIdle, setIsIdle] = useState(false);
  const [isWindowHidden, setIsWindowHidden] = useState(false);
  const idleTimerRef = useRef(null);

  const profile = useSelector(state => state.profile);
  const { logout } = useSession(false);

  // Если профиль — это Клиент, то не даём срабатывать логике Idle/Close
  const isClient = profile?.data?.id && profile?.data?.role_id === 'CLIENT';

  // Время бездействия (15 мин)
  const idleTimeout = 15 * 60 * 1000;

  const resetIdleTimer = useCallback(() => {
    if (isClient) return;
    setIsIdle(false);
    if (idleTimerRef.current) clearTimeout(idleTimerRef.current);

    idleTimerRef.current = setTimeout(() => {
      setIsIdle(true);
    }, idleTimeout);
  }, [idleTimeout, isClient]);

  const handleVisibilityChange = useCallback(() => {
    if (isClient) return;
    const hidden = document.hidden;
    setIsWindowHidden(hidden);
    if (!hidden) {
      resetIdleTimer();
    }
  }, [resetIdleTimer, isClient]);

  const handleLogout = useCallback(() => {
    if (isClient) return;
    if (isIdle) {
      console.log('User is logged out due to inactivity or hidden window.');
      logout('/');
    }
  }, [isIdle, logout, isClient]);

  useEffect(() => {
    if (isClient) return;
    const userEvents = ['mousemove', 'keydown', 'scroll', 'click'];
    userEvents.forEach(event => window.addEventListener(event, resetIdleTimer));
    document.addEventListener('visibilitychange', handleVisibilityChange);
    resetIdleTimer();

    return () => {
      userEvents.forEach(event =>
        window.removeEventListener(event, resetIdleTimer)
      );
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (idleTimerRef.current) clearTimeout(idleTimerRef.current);
    };
  }, [resetIdleTimer, handleVisibilityChange, isClient]);

  useEffect(() => {
    handleLogout();
  }, [isIdle, handleLogout]);

  // ====== Добавляем логаут при закрытии вкладки/окна ======
  useEffect(() => {
    if (isClient) return;

    const handleBeforeUnload = event => {
      localStorage.clear();
      logout('/');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [logout, isClient]);
  // ===================

  return null;
};

export default IdleLogout;
