import { ColDef } from 'ag-grid-community';
import { DownloadCellRenderer } from './cellRenderer/components/DownloadCellRenderer';

import {
  dateFilterComparator,
  formatDate,
  formatSize,
} from '../../../../helpers/common';

export const getLogsColumns = (t: (key: string) => string): ColDef[] => [
  {
    headerName: 'Filename',
    field: 'filename',
    flex: 2.15,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Size',
    field: 'size',
    flex: 1,
    sortable: true,
    valueFormatter: params => {
      if (!params.value) return '0 KB';
      const fileSize = formatSize(params.value);
      return `(${fileSize.size} ${t(`common.dataSizes.${fileSize.type}`)})`;
    },
  },
  {
    headerName: 'Modified Time',
    field: 'modifiedTime',
    flex: 2,
    sortable: true,
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateFilterComparator,
    },

    valueFormatter: params => formatDate(params.value),
  },
  {
    headerName: 'Download',
    field: 'download',
    flex: 1,
    cellRenderer: DownloadCellRenderer,
  },
];
