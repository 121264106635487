import React from 'react';
import { filter, isNil } from 'lodash';
import { Route } from 'react-router-dom';
import Layout from '../ui-kit/layout';
import HomePage from '../../pages/home.page';
import NotFoundPage from '../../pages/not-found.page';

import ErrorPage from '../../pages/error-page';
import Permission from '../ui-kit/permission';
import Spinner from '../ui-kit/spinner';
import ChangePassword from '../change-password/ChangePassword';

// Построение маршрутов на основе модели и разрешений
const onRenderRoute = model => perm => {
  const { id, path, ui_section_id } = perm;

  return (
    <Route
      id={ui_section_id}
      key={ui_section_id}
      path={path}
      element={<Permission />}
    >
      {build(model, id)}
    </Route>
  );
};

// Рекурсивная функция для построения вложенных маршрутов
function build(model, parent_id = null) {
  if (!model) return null;

  const filteredModel = filter(model, { parent_id });
  if (!filteredModel.length) return null;

  return filteredModel.map(onRenderRoute(model));
}

// Маршруты для ролей с неизвестными разрешениями
export const UnknownRoleRoutes = (
  <Route path="/" element={<Spinner label="Виконується вихід..." />}>
    <Route path="*" element={<Spinner label="Виконується вихід..." />} />
  </Route>
);

// Получение URL перенаправления для начального экрана
function getRedirectUrl(permissions) {
  const topLevel = filter(permissions, ({ parent_id }) =>
    isNil(parent_id)
  ).find(p => p?.json?.useOnStartup === true);

  return topLevel?.path || null;
}

// Основной компонент маршрутов
export const BootstrapRoutes = (profile, permissions) => {
  // Проверка профиля
  if (!(profile && profile?.role_id)) {
    console.warn('BootstrapRoutes: Профиль отсутствует или некорректен');
    return UnknownRoleRoutes;
  }

  // Проверка разрешений
  if (!(permissions && permissions.length)) {
    console.warn('BootstrapRoutes: Разрешения отсутствуют или некорректны');
    return UnknownRoleRoutes;
  }

  // Построение маршрутов
  const routes = build(permissions);
  const redirectTo = getRedirectUrl(permissions);

  return (
    <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
      <Route
        key="_home_"
        index
        element={<HomePage redirectTo={redirectTo} />}
      />

      {routes}

      <Route key="_404_" path="*" element={<NotFoundPage />} />
    </Route>
  );
};
