import { FIELDS, TEndPointsManagerComponentOptions } from '../../../fields.dto';

import { find, get } from 'lodash';
import { RULES } from '../../../../../../dto';
import {
  CheckSquareFilled,
  CloseSquareFilled,
  MinusSquareOutlined,
} from '@ant-design/icons';

const AccessRenderer =
  (options: TEndPointsManagerComponentOptions) => (props: any) => {
    const role = props.column.colId;
    const value = get(props?.data, FIELDS.NAME);
    const property = find(options.data.routes, { id: value });
    const rule_id = property?.rule_id;
    const { onClick } = options;

    let $component;
    switch (rule_id) {
      case RULES.ALLOW: {
        $component = <CheckSquareFilled />;
        break;
      }
      case RULES.DENIED: {
        $component = <CloseSquareFilled />;
        break;
      }
      default: {
        $component = <MinusSquareOutlined />;
      }
    }

    function onClickHandler() {
      const $value = rule_id !== RULES.ALLOW;
      onClick && onClick(value, null, $value);
    }

    return (
      <div className="AccessRenderer" onClick={onClickHandler}>
        <div data-cell-renderer={role} data-rule={rule_id}>
          {$component}
        </div>
      </div>
    );
  };
export default AccessRenderer;
