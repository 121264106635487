import { GridApi } from 'ag-grid-community';
import { LogEntry } from '../../../../../types/intarfaces';

export const parseLogContent = (logContent: string): LogEntry[] => {
  const lines = logContent
    .split('\n')
    .map(line => line.trim())
    .filter(Boolean);

  return lines.map(line => {
    try {
      const obj = JSON.parse(line);
      return {
        timestamp: obj['@t'] || '',
        logLevel: obj['@l'] || obj.level || '',
        message: obj['@mt'] || obj['@m'] || '',
        userFullName: obj.who?.fullname || '—',
        userPosition: obj.who?.position || '—',
        ticketId: obj.data?.ticket_id || obj.ticket_id || '—',
        companyName: obj.data?.company?.companyName || '—',
        fileName: obj.files?.[0]?.name || '—',
        severity: obj.data?.severity || obj.level || '—',
        ...obj,
      };
    } catch {
      return {
        timestamp: '',
        logLevel: 'PARSE_ERROR',
        message: line,
        userFullName: '',
        userPosition: '',
        ticketId: '',
        companyName: '',
        fileName: '',
        severity: '',
      };
    }
  });
};

// Инициализация видимости колонок
export const initializeVisibleCols = (
  columns: { colId: string }[]
): Record<string, boolean> => {
  const initial: Record<string, boolean> = {};
  columns.forEach(({ colId }) => {
    initial[colId] = true;
  });
  return initial;
};

// Применение видимости колонок
export const applyColumnsVisibility = (
  gridApi: GridApi,
  visibleCols: Record<string, boolean>
) => {
  const columnsVisibility = Object.entries(visibleCols).map(
    ([colId, isVisible]) => ({ colId, visible: isVisible })
  );

  columnsVisibility.forEach(({ colId, visible }) => {
    gridApi.setColumnsVisible([colId], visible);
  });
};
