import Logger from '../helpers/logger';
import { w3cwebsocket as W3C } from 'websocket';
import { Error_I } from '../types';
import { useReduxDispatch, useReduxSelector } from './redux.hook';
import { socketActions } from '../store/slices/socket.slice';
import md5 from 'md5';
import { jsonToObject } from '../helpers/common';
import EventDispatcher, {
  DispatchableEvent,
} from '../helpers/event-dispatcher';
import Session from '../helpers/session';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';

export type TSocketHook = {
  socket: W3C | null;
  dispatcher: EventDispatcher;
  error: Error_I | null;
  isReady: boolean;
  init: Function;
  send: Function;
  destroy: Function;
  doPingPong: Function;
};

const dispatcher = new EventDispatcher();
let $_singletonInstance: any = null;

export enum SOCKET_DATA_TYPES {
  MESSAGE_CHAT = 'MESSAGE_CHAT',
  MESSAGE_P2P = 'MESSAGE_P2P',
  NOTIFY_CHANGES = 'NOTIFY_CHANGES',
}

const MAX_RECONNECT_ATTEMPTS = 5;
let reconnectAttempts = 0;

let _currentHost = '';

let _PingPongID: any = null;
function useSocketHook(): TSocketHook {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const { isReady, error } = useReduxSelector(({ socket }) => socket);
  const { update, reset } = socketActions;
  const _isReady = isReady && $_singletonInstance?.readyState === 1;

  const destroy = useCallback(() => {
    if ($_singletonInstance) {
      Logger.error('Socket() destroy()');
      $_singletonInstance.onopen = null;
      $_singletonInstance.onerror = null;
      $_singletonInstance.onclose = null;
      $_singletonInstance.onmessage = null;
      $_singletonInstance.close();
      $_singletonInstance = null;
    }
    reset();
  }, [reset]);

  const _onOpen = useCallback(
    (event: any) => {
      Logger.warn('useSocketHook() _onOpen([ event ])', event);
      const connection: any = event?.target;
      const isReady = Boolean(connection);

      if (isReady) {
        $_singletonInstance = connection;
        reconnectAttempts = 0;
        dispatch(update({ isReady: true }));
      } else {
        destroy();
        dispatch(update({ isReady: false }));
      }
    },
    [dispatch, update, destroy]
  );

  const setError = useCallback(
    (error: Error_I) => {
      clearPingPong();
      dispatch(update({ isReady: false, error }));
    },
    [dispatch, update]
  );

  const _onError = useCallback(
    (event: any) => {
      Logger.error('useSocketHook() _onError([ error ])', event);
      const message: string = `${t('errors.chatDisabled')}`;
      setError({ message });
    },
    [setError, t]
  );

  const _onClose = useCallback(
    (event: any) => {
      Logger.warn('useSocketHook() _onClose([ event ])', event);

      // Если не превысили лимит попыток, пробуем переподключиться
      if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
        reconnectAttempts++;
        const delayMs = 2000 * reconnectAttempts; // простой back-off (2с, 4с, 6с...)
        Logger.warn(
          `Trying to reconnect #${reconnectAttempts} in ${delayMs} ms`
        );
        setTimeout(() => {
          if (!document.hidden) {
            // Если вкладка активна — переподключаемся
            init(_currentHost);
          } else {
            // Если вкладка неактивна — дождёмся её открытия (сработает visibilitychange)
            Logger.warn('Tab is hidden, skipping reconnect until visible');
          }
        }, delayMs);
      } else {
        Logger.error(
          `Max reconnect attempts (${MAX_RECONNECT_ATTEMPTS}) reached. Stopping attempts.`
        );
      }
    },
    [setError, t]
  );

  function _onMessage(event: any) {
    const payload: any = jsonToObject(event.data);
    const { type, data } = payload;
    dispatcher.dispatchEvent(new DispatchableEvent(type, data));
  }

  function doPingPong(interval: number /* sec */) {
    if (isReady && !error) {
      _PingPongID = setInterval(() => {
        /* debug */ Logger.saga('socket::ping');
        send('#ping');
      }, interval * 60 * 1000);
    }
  }
  function clearPingPong() {
    if (_PingPongID) {
      clearInterval(_PingPongID);
      _PingPongID = null;
    }
  }

  const init = useCallback(
    (host: string) => {
      _currentHost = host;
      const access_token: string = Session.getToken() || '';
      try {
        const url: string = `${host}?token=${md5(access_token)}`;
        Logger.info(`useSocketHook() init to: ${url}`);

        const socket_: any = new W3C(url);
        socket_.onopen = _onOpen;
        socket_.onerror = _onError;
        socket_.onclose = _onClose;
        socket_.onmessage = _onMessage;
      } catch (error: any) {
        setError({ message: error?.message });
      }
    },
    [_onClose, _onError, _onOpen, setError]
  );
  function send(text: string, data: any = null) {
    _isReady &&
      (text || data) &&
      $_singletonInstance.send(JSON.stringify({ text, data }));
  }

  /* debug */ Logger.info('useSocketHook()', { error, isReady });

  useEffect(() => {
    function handleVisibilityChange() {
      if (!document.hidden) {
        // вкладка снова активна
        const access_token = Session.getToken();
        if (access_token && (!isReady || error)) {
          init(
            _currentHost ||
              process.env.REACT_APP_WEBSOCKET_HOST ||
              'ws://default-host:5777'
          );
        }
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isReady, error, init]);

  Logger.info('useSocketHook()', { error, isReady });

  return {
    isReady: _isReady,
    doPingPong,
    dispatcher,
    error,
    socket: $_singletonInstance,
    send,
    init,
    destroy,
  };
}

export default useSocketHook;
