import Section from '../ui-kit/section';
import Login from './Login';
import '../../styles/components/auth.scss';

export default function Auth() {
  return (
    <Section className="Auth" classAsideName="wrapper" aside={false}>
      <Login />
    </Section>
  );
}
