import React, { useEffect } from 'react';
import OpenFiles from '../OpenFiles';
import map from 'lodash';
import { PID } from '../../../../../types/prop-types';
import { useFileManagerHook } from '../../../../../hooks/useFileManagerHook';
import FilesExplorer from '../FilesExplorer';
import PropTypes from 'prop-types';
import Logger from '../../../../../helpers/logger';

function FileManager(props) {
  /* debug */ Logger.info('FileManager([ props ])', props);

  const {
    id,
    disableFileStatusSelection,
    disableSignersSelection,
    isMultipleSelectionEnabled,
    isReadOnly,
    isOwner,
    company_id,
    remoteFiles,
    onSelectFiles,
    onSingersUpdate,
    onFilesListUpdate,
    onUploaderProgress,
    onChangeFileStatus,
  } = props;
  const _filesManagerInstance = useFileManagerHook({ id, onUploaderProgress });
  const {
    addMany,
    data: localFiles,
    setAltNameByHash,
    setCommentByHash,
    remove,
    setFileStatus,
  } = _filesManagerInstance;

  function onOpenFilesHandler(files) {
    addMany(files);
  }
  function onChangeFileName({ hash: id }, newFileName, extension) {
    setAltNameByHash(id, newFileName, extension);
  }
  function onChangeComment({ hash: id }, comment) {
    setCommentByHash(id, comment);
  }
  function onRemoveLocalFile({ hash: id }) {
    id && remove(id);
  }
  function onChangeLocalFileStatus(file) {
    /* debug */ Logger.warn(
      'FileManager() onChangeLocalFileStatus([ file ])',
      file
    );
    setFileStatus(file);
  }
  function onChangeFileStatusHandler(file) {
    /* debug */ Logger.log(
      'FileManager() onChangeFileStatusHandler([ file ])',
      file?.isLocal,
      file
    );

    const isLocalFile = Boolean(file?.isLocal === true);

    !isLocalFile ? onChangeFileStatus(file) : onChangeLocalFileStatus(file);
  }

  useEffect(() => {
    onFilesListUpdate && onFilesListUpdate(_filesManagerInstance);
  }, [localFiles]);

  const _remoteFiles = map(remoteFiles, (file, index) => {
    return {
      comment: file?.comment,
      name: file?.name,
      error: file?.error,
      hash: file?.hash,
      size: file?.size,
      type: file?.mimetype,
      isLocal: false,
      isRenameEnabled: false,
      lastModified: file?.updatedAt,
    };
  });
  const files = [...(localFiles || []), ...(_remoteFiles || [])];

  return files && files.length ? (
    <FilesExplorer
      files={files}
      isMultipleSelectionEnabled={isMultipleSelectionEnabled}
      isReadOnly={isReadOnly}
      isOwner={isOwner}
      disableSignersSelection={disableSignersSelection}
      disableFileStatusSelection={disableFileStatusSelection}
      company_id={company_id}
      onSignersUpdate={onSingersUpdate}
      onRemoveLocalFile={onRemoveLocalFile}
      onChangeFileName={onChangeFileName}
      onChangeComment={onChangeComment}
      onNewFilesAdded={onOpenFilesHandler}
      onSelectFiles={onSelectFiles}
      onChangeFileStatus={onChangeFileStatusHandler}
    />
  ) : (
    <OpenFiles id={id} disabled={isReadOnly} onOpen={onOpenFilesHandler} />
  );
}
FileManager.defaultProps = {
  isOwner: false,
  isReadOnly: false,
  disableSignersSelection: false,
  disableFileStatusSelection: false,
};
FileManager.propTypes = {
  id: PID,
  isOwner: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  disableSignersSelection: PropTypes.bool,
  disableFileStatusSelection: PropTypes.bool,
  company_id: PropTypes.number,
  remoteFiles: PropTypes.array,
  isMultipleSelectionEnabled: PropTypes.bool,
  onSingersUpdate: PropTypes.array,
  onFilesListUpdate: PropTypes.func,
  onUploaderProgress: PropTypes.func,
  onSelectFiles: PropTypes.func,
  onChangeFileStatus: PropTypes.func,
};

export default FileManager;
