import { useAppDispatch } from '../../../store/hook';
import { setSelectedFile } from '../../../store/logs/logsSlice';
import { fetchLogsThunk } from '../../../store/logs/logsOperations';

import Toolbar from './Toolbar';
import { LogViewer } from './LogViewer/LogViewer';
import { LogsTable } from './LogsTable/LogsTable';

import '../../../styles/components/logs.scss';
export const LogsExplorer = () => {
  const dispatch = useAppDispatch();

  const handleRefresh = () => {
    dispatch(setSelectedFile(''));
    dispatch(fetchLogsThunk());
  };

  return (
    <section className="Logs">
      <div className="wrapper">
        <div className="LogsComponent">
          <Toolbar onRefresh={handleRefresh} />

          <LogsTable />

          <LogViewer />
        </div>
      </div>
    </section>
  );
};
