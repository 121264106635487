import { RootState } from '../store';

export const selectLogsState = (state: RootState) => state.logs;

export const selectLogFiles = (state: RootState) => state.logs.files;
export const selectLogFilesLoading = (state: RootState) =>
  state.logs.filesLoading;
export const selectLogFilesError = (state: RootState) => state.logs.filesError;

export const selectLogContent = (state: RootState) => state.logs.logContent;
export const selectLogLoading = (state: RootState) => state.logs.logLoading;
export const selectLogError = (state: RootState) => state.logs.logError;
export const selectSelectedFile = (state: RootState) => state.logs.selectedFile;
