import { Tooltip } from 'antd';
import { TString } from '../../../../../../types';
import { jsonToObject } from '../../../../../../helpers/common';
import { TUsersManagerComponentOptions } from '../../../fields.dto';

const DepRenderer =
  (options: TUsersManagerComponentOptions) => (props: any) => {
    // /* debug */ Logger.info( "DepRenderer([ options, props ])", { options, props } ) ;

    const { fromI18N } = options;
    const {
      data: { dep_title, dep_description },
    } = props;

    function getTitle(): TString {
      return !dep_title ? null : fromI18N(jsonToObject(dep_title));
    }
    function getDescription(): TString {
      return !dep_description ? null : fromI18N(jsonToObject(dep_description));
    }

    const title = getTitle();
    const description = getDescription();

    // /* debug */ Logger.warn( "DepRenderer([ dep_id, dep_name, dep_title ])", { dep_id, dep_name, dep_title } ) ;

    return (
      <div className="DepRenderer">
        <Tooltip placement="top" title={description}>
          <b>{title}</b>
        </Tooltip>
      </div>
    );
  };
export default DepRenderer;
