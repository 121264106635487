import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/components/app_preloader.scss';

export default function AppPreloader() {
  const { t } = useTranslation();
  return (
    <div className="AppPreloader">
      <div className="logo">
        <p>A</p>
      </div>
      <p className="text">{t('common.appLoading')}</p>
    </div>
  );
}
