import { TLanguageLocalStoreData, TObject, TString } from '../types';
import { LocalStore_I } from '../types/intarfaces';
import { LocalStoreKeys } from '../data/constants';
import { trim } from 'lodash';
import LocalStorage from './local-storage';
import MD5 from 'md5';
import moment from 'moment';

export function deepClone(data: any): any {
  return jsonToObject(JSON.stringify(data));
}
export function jsonToObject(json: string): TObject {
  let result: TObject;
  try {
    result = JSON.parse(json);
  } catch (error) {
    result = null;
  }
  return result;
}
export function getCheckSum(value: any): string {
  return <string>MD5(JSON.stringify(value || {}));
}
export function getLanguageFromLocalStore(): TString {
  const ls: LocalStore_I = LocalStorage(LocalStoreKeys.I18N);
  const data: TLanguageLocalStoreData = ls.getState();
  const language: TString = data?.i18n;

  return language;
}
export function getCSSVariableValue(name: string): any {
  if (typeof window)
    return trim(
      `${
        window
          .getComputedStyle(document.documentElement)
          .getPropertyValue(name) || ''
      }`
    );
  return null;
}
export function setCSSVariableValue(name: string, value: any) {
  typeof window && document.documentElement.style.setProperty(name, value);
  return null;
}

type TFormatSizeFunc = {
  size: string;
  type: string;
};
export function formatSize(byte: number): TFormatSizeFunc {
  const units = ['b', 'k', 'm', 'g', 't'];
  let counter: number = 0;
  let kb: number = 1024;
  let div: number = byte / 1;
  while (div >= kb) {
    counter++;
    div = div / kb;
  }
  return {
    size: div.toFixed(1),
    type: units[counter],
  };
}
export function shortenString(
  data: string,
  limit: number,
  end: string = ''
): string {
  const value = `${data || ''}`.trim();
  const cut = value.substring(0, limit).trim();
  const isLengthChanged = cut.length < value.length;

  return `${cut}${isLengthChanged ? (cut.length ? end : '') : ''}`;
}

type TParseFileNameFunc = {
  name: string;
  extension?: string;
};
export function parseFileName(filename: string = ''): TParseFileNameFunc {
  const data: string[] = filename.split('.') || '';
  const extension: string = <string>data.pop();
  const name: string = data.join('.');

  return { name, extension };
}

export function scrollToBottom(id: string) {
  const element: any | null = document.getElementById(id);
  if (!element) return;
  element.scrollIntoView({ block: 'center' });
}

export const formatDate = (date: string | undefined) => {
  return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '';
};

export const dateFilterComparator = (
  filterLocalDateAtMidnight: Date,
  cellValue: string
) => {
  const cellDate = moment(cellValue).startOf('day').toDate();
  const filterDate = moment(filterLocalDateAtMidnight).startOf('day').toDate();

  if (cellDate < filterDate) {
    return -1;
  }
  if (cellDate > filterDate) {
    return 1;
  }
  return 0;
};
