import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { REVIEW_STATUSES, SORT_DIRECTIONS, UI } from '../../../dto';
import Logger from '../../../helpers/logger';
import { find, first, get, map, uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  BorderlessTableOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  CloseCircleFilled,
  DatabaseFilled,
  SearchOutlined,
} from '@ant-design/icons';
import { Input, Table } from 'antd';
import { PTPaginator } from '../../../types/prop-types';
import useI18N from '../../../hooks/useI18N.hook';
import CellRenderers, { SupervisorReviewColumns } from './CellRenderers';
import { useReduxSelector } from '../../../hooks/redux.hook';
import useResizeObserver from '../../../hooks/useResizeObserver';
import ClassNames from 'classnames';
import '../../../styles/components/my_tickets.scss';
import { TABLE_CELL_WIDTH_DEFAULT } from '../../../data/constants';

const { Search } = Input;

function SearchKeywords({ keyword, placeholder, onSearch }) {
  const [value, setValue] = useState(keyword);
  function onClearSearchHandler() {
    const value = null;
    setValue(value);
    onSearch(value);
  }
  function onChangeHandler({ target }) {
    setValue(target?.value);
  }
  function onSearchHandler() {
    onSearch(value);
  }

  return (
    <span className="search-wrapper">
      <Search
        className="search"
        placeholder={placeholder}
        enterButton={<SearchOutlined className="icon-search" />}
        size="middle"
        autoFocus={true}
        value={value}
        suffix={
          value &&
          value.length && (
            <CloseCircleFilled
              className="icon-clear"
              onClick={onClearSearchHandler}
            />
          )
        }
        onChange={onChangeHandler}
        onSearch={onSearchHandler}
      />
    </span>
  );
}
function SupervisorReviewComponent(props) {
  /* debug */ Logger.info('SupervisorReviewComponent([ props ])', props);

  const {
    menu,
    filter,
    fieldsAllowed,
    data,
    paginator,
    search,
    onPageSize,
    onPage,
    onSearch,
    onSort,
    onRemove,
    onTakeForWork,
    onCallDepsList,
    onCallEmployeeList,
    onMenuChange,
  } = props;
  const { fromI18N } = useI18N();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const id = UI.DEP_TICKETS;
  const { value: i18n } = useI18N();
  const { t } = useTranslation();
  const { data: profile } = useReduxSelector(({ profile }) => profile);
  const { data: deps } = useReduxSelector(({ deps }) => deps);
  const { data: permissions } = useReduxSelector(
    ({ permissions }) => permissions
  );
  const cellRendererCollectionOptions = {
    i18n,
    fromI18N,
    profile,
    deps,
    t,
    permissions,
    onRemove,
    onTakeForWork,
    onCallDepsList,
    onCallEmployeeList,
  };
  const CellRendererCollection = useMemo(
    () => CellRenderers(cellRendererCollectionOptions),
    [i18n]
  );
  const onResizeHandler = useCallback(target => {
    setDimensions({
      width: target.offsetWidth,
      height: target.offsetHeight,
    });
  }, []);

  function onSearchHandler(value) {
    onSearch && onSearch(value);
  }
  function onSortHandler(sortBy, sortDirection) {
    return () => {
      if (
        sortBy === paginator?.sortBy &&
        sortDirection === paginator?.sortDirection
      )
        return null;
      onSort && onSort(sortBy, sortDirection);
    };
  }

  const columns = fieldsAllowed.map((id, key) => {
    const title = t(`DepTickets.columns.${id}`);
    const isSortable = !Boolean(id.split('$').length - 1);
    const isSelected = id === paginator?.sortBy;
    return {
      key,
      width: TABLE_CELL_WIDTH_DEFAULT,
      title: (
        <div className="header" data-sort={isSortable}>
          <div className="title" data-selected={isSelected}>
            {title}
          </div>
          {isSortable && (
            <div className="toolbar">
              <CaretUpOutlined
                key="asc"
                className={ClassNames(
                  'sort',
                  isSelected && paginator?.sortDirection === SORT_DIRECTIONS.ASC
                    ? 'active'
                    : ''
                )}
                onClick={onSortHandler(id, SORT_DIRECTIONS.ASC)}
              />
              <CaretDownOutlined
                key="desc"
                className={ClassNames(
                  'sort',
                  isSelected &&
                    paginator?.sortDirection === SORT_DIRECTIONS.DESC
                    ? 'active'
                    : ''
                )}
                onClick={onSortHandler(id, SORT_DIRECTIONS.DESC)}
              />
            </div>
          )}
        </div>
      ),
      showSorterTooltip: false,
      dataIndex: id,
      render: (value_, data_, index) => {
        const renderer = get(CellRendererCollection, id, null);
        return !renderer
          ? value_
          : renderer({ id, key, index, value: value_, data: data_ });
      },
    };
  });
  const stageRef = useResizeObserver(onResizeHandler);

  function onSubMenuItemClickHandler(id) {
    return () => onMenuChange && onMenuChange(find(menu, { id }));
  }
  function getMenuItemByStatusID(options) {
    const defaults = first(menu);
    const { review_status_id } = options || {};

    if (!review_status_id) return defaults;

    const item = find(menu, { review_status_id });
    return item || defaults;
  }
  const activeMenuItem = getMenuItemByStatusID(filter);
  const menuItems = map(menu, ({ id, i18n, icon }) => (
    <div
      key={id}
      data-active={activeMenuItem?.id === id}
      className="sub-menu-item"
      onClick={onSubMenuItemClickHandler(id)}
    >
      {icon}
      {t(i18n)}
    </div>
  ));

  return (
    <div className="SupervisorReview">
      <div className="sub-menu">{menuItems}</div>
      <div className="context">
        <div className="wrapper">
          <div className="toolbar">
            <SearchKeywords
              placeholder={t('common.search')}
              keyword={search}
              onSearch={onSearchHandler}
            />
          </div>
          <div className="stage" ref={stageRef}>
            {dimensions.height && (
              <Table
                // bordered={ true }
                sortDirections={['ascend', 'descend']}
                tableLayout="auto"
                className="DepTicketsTable"
                size="small"
                rowClassName={(record, index) => {
                  const isNotOwner = !Boolean(record.is_owner);
                  return isNotOwner ? 'not-owner' : null;
                }}
                pagination={{
                  locale: {
                    items_per_page: `/ ${t(
                      'components.paginator.pagePerLimit'
                    ).toLowerCase()}`,
                  },
                  size: 'small',
                  current: parseInt(paginator?.pageNo) + 1,
                  showTitle: false,
                  showTotal: total =>
                    t('components.paginator.total', { total }),
                  total: paginator?.total,
                  pageSize: paginator?.perPageLimit,
                  pageSizeOptions: [5, 15, 30, 50],
                  showSizeChanger: true,
                  position: ['bottomLeft'],
                  onShowSizeChange: onPageSize,
                  onChange: onPage,
                }}
                scroll={{ y: `${dimensions.height - 125}px` }}
                dataSource={data}
                columns={columns}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
SupervisorReviewComponent.defaultProps = {
  menu: [
    {
      id: uniqueId(),
      review_status_id: null,
      i18n: 'DepTickets.menu.all',
      icon: <DatabaseFilled />,
    },
    {
      id: uniqueId(),
      review_status_id: REVIEW_STATUSES.RV_ON_REVIEW,
      i18n: `StatusSelector.review_statuses.RV_ON_REVIEW`,
      icon: <BorderlessTableOutlined />,
    },
    {
      id: uniqueId(),
      review_status_id: REVIEW_STATUSES.RV_REJECTED,
      i18n: `StatusSelector.review_statuses.RV_REJECTED`,
      icon: <BorderlessTableOutlined />,
    },
    {
      id: uniqueId(),
      review_status_id: REVIEW_STATUSES.RV_APPROVED,
      i18n: `StatusSelector.review_statuses.RV_APPROVED`,
      icon: <BorderlessTableOutlined />,
    },
  ],
  data: [],
  fieldsAllowed: [
    SupervisorReviewColumns.review_status_id,
    SupervisorReviewColumns.id,
    SupervisorReviewColumns.createdAt,
    SupervisorReviewColumns.updatedAt,
    SupervisorReviewColumns.owner_id,
    SupervisorReviewColumns.dep_id,

    SupervisorReviewColumns.subject,
    SupervisorReviewColumns.description,
    SupervisorReviewColumns.userId,
    SupervisorReviewColumns.status_id,
    '$toolbar',
  ],
  onMenuChange: (...rest) =>
    Logger.warn(
      'SupervisorReviewComponent::onMenuChange([ ... rest ])',
      ...rest
    ),
  onPage: (...rest) =>
    Logger.warn('SupervisorReviewComponent::onPage([ ... rest ])', ...rest),
  onPageSize: (...rest) =>
    Logger.warn('SupervisorReviewComponent::onPageSize([ ... rest ])', ...rest),
  onSearch: (...rest) =>
    Logger.warn('SupervisorReviewComponent::onSearch([ ... rest ])', ...rest),
  onSort: (...rest) =>
    Logger.warn('SupervisorReviewComponent::onSort([ ... rest ])', ...rest),
};
SupervisorReviewComponent.propTypes = {
  menu: PropTypes.array,
  data: PropTypes.array,
  fieldsAllowed: PropTypes.array,
  fetching: PropTypes.bool,
  paginator: PTPaginator,
  filter: PropTypes.object,
  search: PropTypes.string,
  onMenuChange: PropTypes.func,
  onPage: PropTypes.func,
  onPageSize: PropTypes.func,
  onSearch: PropTypes.func,
  onSort: PropTypes.func,
  onRemove: PropTypes.func,
  onTakeForWork: PropTypes.func,
  onCallDepsList: PropTypes.func,
  onCallEmployeeList: PropTypes.func,
};

export default SupervisorReviewComponent;
