import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { assignTaskForReview, updateTask } from '../../../helpers/api.services';
import { ROLES, RV_UNVERIFIED } from '../../../dto';
import { CellRendererWrapper } from './CellRenderers';
import Button from '../../ui-kit/button';
import Message from '../../ui-kit/message';
import ReviewStatusWrapper from '../../ui-kit/review_status/wrapper';
import Logger from '../../../helpers/logger';
import { LoadingOutlined } from '@ant-design/icons';
import useMyTickets from '../../../hooks/useMyTickets.hook';

function SendForCheckButton({ id, label, disabled, onData }) {
  const [fetching, setFetching] = useState(false);
  const [_disabled, setDisabled] = useState(disabled);

  async function fetch() {
    /* debug */ Logger.log('SendForCheckButton() fetch()');

    setFetching(true);

    if (!id) return;

    let payload = null,
      error = null;

    try {
      payload = await assignTaskForReview(id);
    } catch (exception) {
      error = exception;
    } finally {
      onData && onData({ payload, error });
    }

    setFetching(false);
  }
  function onSendForCheckHandler() {
    /* debug */ Logger.warn('SendForCheckButton() onSendForCheckHandler()');
    setDisabled(true);
    fetch();
  }

  return (
    <Button
      icon={fetching ? <LoadingOutlined /> : null}
      disabled={_disabled}
      className="send-for-check"
      label={label}
      onClick={onSendForCheckHandler}
    />
  );
}
SendForCheckButton.defaultProps = {
  disabled: false,
  label: '',
};
SendForCheckButton.propTypes = {
  id: PropTypes.number,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onData: PropTypes.func,
};

function ReviewStatusIdCellRenderer(props) {
  const { update: updateTicket } = useMyTickets();
  const { t, profile, data, value } = props;
  const [state, setState] = useState();
  const [status, setStatus] = useState(value || RV_UNVERIFIED);
  const { role_id } = profile;
  const { id: ticket_id } = data;
  const title = t(`StatusSelector.review_statuses.${status}`);
  const isCanSendForReview =
    [RV_UNVERIFIED].includes(status) && [ROLES.OPERATOR].includes(role_id);

  function onDataHandler(_data) {
    /* debug */ Logger.warn(
      'ReviewStatusIdCellRenderer() onDataHandler([ _data ])',
      _data
    );
    setState(_data);
    _data?.payload?.data?.review_status_id &&
      setStatus(_data.payload.data.review_status_id);
  }
  async function onReviewStatusSave(status) {
    const { data } = await updateTask(ticket_id, status);
    updateTicket(ticket_id, status);
    data?.review_status_id && setStatus(data?.review_status_id);
  }

  /* debug */ Logger.info('ReviewStatusIdCellRenderer([!])', title, status);

  return (
    <CellRendererWrapper {...props}>
      {!state?.error?.message ? (
        <ReviewStatusWrapper
          id={status}
          description={data?.review_status_description}
          onSave={onReviewStatusSave}
        >
          {!isCanSendForReview ? (
            title
          ) : (
            <SendForCheckButton
              id={ticket_id}
              label={t('common.send_for_check')}
              onData={onDataHandler}
            />
          )}
        </ReviewStatusWrapper>
      ) : (
        <Message type={Message.types.ERROR} message={state?.error?.message} />
      )}
    </CellRendererWrapper>
  );
}
ReviewStatusIdCellRenderer.propTypes = {
  profile: PropTypes.object,
  i18n: PropTypes.any,
  t: PropTypes.any,
  id: PropTypes.string,
  value: PropTypes.string,
  key: PropTypes.number,
  data: PropTypes.any,
  index: PropTypes.number,
};

export default ReviewStatusIdCellRenderer;
