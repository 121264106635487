import React from 'react';
import PropTypes from 'prop-types';
import Label from '../label';
import { map } from 'lodash';
import KeyIcon from '../key_icon';
import Button from '../button';
import { useTranslation } from 'react-i18next';
import Logger from '../../../helpers/logger';

import '../../../styles/components/ui-kit/company_users.scss';

function CompanyUsers({ selected, data, onSelect, onInvite }) {
  const { t } = useTranslation();
  const items = map(data, (value, key) => {
    const {
      id,
      position,
      signRights,
      userLastName,
      userFirstName,
      userMiddleName,
      isExists,
    } = value;
    const fullname = `${userFirstName || ''} ${userMiddleName || ''} ${
      userLastName || ''
    }`.trim();
    const isSelected = selected && selected === id;

    function onSelectHandler() {
      if (value?.id === selected) return;
      isExists && onSelect && onSelect(value);
    }
    function onInviteHandler() {
      onInvite && onInvite(value);
    }

    return (
      <div
        key={key}
        className="item"
        data-selected={isSelected}
        data-disabled={!isExists || isSelected}
        onClick={onSelectHandler}
      >
        <div className="context">
          <b>{position || t('errors.userPositionIsUndeclared')}</b>
          <Label
            value={
              <>
                {fullname}
                {<KeyIcon signRights={signRights} />}
              </>
            }
          />
        </div>
        <div className="actions">
          {!isExists && (
            <Button
              className="invite"
              label={t('common.invite')}
              onClick={onInviteHandler}
            />
          )}
        </div>
      </div>
    );
  });

  return (
    <div className="CompanyUsers">
      <div className="list">{items}</div>
    </div>
  );
}
CompanyUsers.defaultProps = {
  selected: PropTypes.number,
  data: [],
  onSelect: (...rest) =>
    Logger.warn('CompanyUsers::onSelect([ ... rest ])', ...rest),
  onInvite: (...rest) =>
    Logger.warn('CompanyUsers::onInvite([ ... rest ])', ...rest),
};
CompanyUsers.propTypes = {
  selected: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.number,
      position: PropTypes.string,
      signRights: PropTypes.number,
      userLastName: PropTypes.string,
      userFirstName: PropTypes.string,
      userMiddleName: PropTypes.string,
    })
  ),
  onSelect: PropTypes.func,
  onInvite: PropTypes.func,
};

export default CompanyUsers;
