import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLogFile, getLogs } from '../../helpers/api.services';

export const fetchLogsThunk = createAsyncThunk(
  'logs/fetchLogs',
  async (_, thunkAPI) => {
    try {
      const { data } = await getLogs();

      return data.files;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchLogFileThunk = createAsyncThunk(
  'logs/fetchLogFile',
  async (filename: string, thunkAPI) => {
    try {
      const { data } = await getLogFile(filename);

      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
