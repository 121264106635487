import React, { FC, useCallback } from 'react';
import { GridApi } from 'ag-grid-community';

interface QuickFilterProps {
  label: string;
  gridApiRef: React.MutableRefObject<GridApi | null>;
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
}

interface GridApiWithQuickFilter extends GridApi {
  setQuickFilter: (filter: string) => void;
}
export const QuickFilter: FC<QuickFilterProps> = ({
  label = '',
  gridApiRef,
  value,
  setValue,
  placeholder,
}) => {
  const handleFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setValue(val);
      if (gridApiRef.current) {
        (gridApiRef.current as GridApiWithQuickFilter).setQuickFilter(val);
      }
    },
    [gridApiRef, setValue]
  );

  return (
    <>
      <label className="title" htmlFor="quickFilterInput">
        {label || ''}
      </label>
      <input
        id="quickFilterInput"
        type="search"
        value={value}
        onChange={handleFilterChange}
        placeholder={placeholder || 'Введіть текст...'}
        aria-label={label || 'Quick filter'}
        role="searchbox"
      />
    </>
  );
};
