import React from 'react';
import PropTypes from 'prop-types';
import Logger from '../../../../../helpers/logger';
import { formatSize } from '../../../../../helpers/common';
import MomentJS from 'moment';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DATE_TIME_FORMAT } from '../../../../../data/constants';
import '../../../../../styles/components/file_info.scss';

function FileProperties({ file }) {
  /* debug */ Logger.info('FileProperties([ file ])', file);

  const { t } = useTranslation();
  const fileSize = formatSize(file.size);
  const data = [
    {
      label: t('FileInfo.FileProperties.fields.owner'),
      value: (
        <>
          <UserOutlined />
          {!file.$owner.isOwner
            ? `${file.$owner.userFirstName || ''} ${
                file.$owner.userMiddleName || ''
              } ${file.$owner.userLastName || ''}`.trim()
            : t('FileInfo.FileProperties.self')}
        </>
      ),
    },
    {
      label: t('FileInfo.FileProperties.fields.file_name'),
      value: `${file.name}`.trim(),
    },
    { label: t('FileInfo.FileProperties.fields.type'), value: file.mimetype },
    {
      label: t('FileInfo.FileProperties.fields.date'),
      value: MomentJS(file.createdAt).format(DATE_TIME_FORMAT),
    },
    {
      label: t('FileInfo.FileProperties.fields.size'),
      value: `${fileSize.size} ${t(`common.dataSizes.${fileSize.type}`)}`,
    },
    {
      label: t('FileInfo.FileProperties.fields.comment'),
      value: `${file.comment || ''}`.trim(),
    },
  ];

  return (
    <div className="FileProperties">
      <div className="wrapper">
        {data.map(({ label, value }, key) => {
          return (
            <div key={key} className="item">
              <p>
                <b>{label}:</b>
                {value}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
FileProperties.propTypes = {
  file: PropTypes.shape(),
};

export default FileProperties;
