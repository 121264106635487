import { UI } from '../../dto';
import Profile from './profile';
import MyTickets from './my_tickets';
import TicketProperty from './ticket_property';
import DepsTickets from './dep_tickets';
import SupervisorReview from './supervisor_review';
import Users from './users';
import EndPoints from './endpoints';
import { LogsExplorer } from './LogsExplorer/LogsExplorer';

export default {
  [UI.PROFILE]: Profile,
  [UI.MY_TICKETS]: MyTickets,
  [UI.CREATE_NEW_TICKET]: TicketProperty,
  [UI.TICKET_PROPERTY]: TicketProperty,
  [UI.DEP_TICKETS]: DepsTickets,
  [UI.SUPERVISOR_REVIEW]: SupervisorReview,
  [UI.USERS]: Users,
  [UI.ENDPOINTS]: EndPoints,
  [UI.LOGS]: LogsExplorer,
};
