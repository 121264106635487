import React, { useEffect, useMemo } from 'react';
import {
  AuditOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  FileProtectOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Badge, Modal, Tooltip } from 'antd';
import useCompanySigners from '../../../../../hooks/useCompanySigners.hook';
import Logger from '../../../../../helpers/logger';
import { filter, get, isNil, keys, map, merge, reject } from 'lodash';
import Header from '../../../../ui-kit/header';
import ClassNames from 'classnames';
import Label from '../../../../ui-kit/label';
import CompanySigners from '../../../../ui-kit/company_signers';
import { SIGN_RIGHTS } from '../../../../../dto';
import { useReduxSelector } from '../../../../../hooks/redux.hook';

type TSignCellViewProps = {
  file: any;
  isOwner: boolean;
  isReadOnly: boolean;
  company_id: number;
  onSelect: Function;
};
export default function SignCellView(props: TSignCellViewProps) {
  const { t } = useTranslation();
  const { isOwner, isReadOnly, company_id, file, onSelect } = props;
  const isLocalFile = File.prototype.isPrototypeOf(file);
  const {
    isStateLoading,
    isStateReady,
    isVerificationLoading,
    isVerificationReady,
    verify,
    verified,
    prepare,
    reset,
    state,
  } = useCompanySigners();
  const { data: profile } = useReduxSelector(({ profile }) => profile);
  const isAllReady = isStateReady && isVerificationReady;
  const isAllLoading = isStateLoading || isVerificationLoading;
  const error = state?.error || verified?.error;

  /* debug */ Logger.log('SignCellView()', props);

  async function initialize() {
    prepare({ company_id });
  }

  function verifyEmployees() {
    if (!isStateReady)
      return /* error */ Logger.error(
        'SignCellView([ error ])',
        `"state" must be ready!`
      );
    const employeesIds = map(state.data, ({ userId }) => userId);
    if (!isStateReady)
      return /* debug */ Logger.log(
        'SignCellView() verifyEmployees([ employeesIds ])',
        employeesIds
      );

    verify(company_id, employeesIds);
  }
  function onSignersFilter(data: any[]) {
    return reject(
      map(data, (user, index) => {
        const { signRights, isExists } = user;
        const isSigner = signRights < SIGN_RIGHTS.DISABLED;

        return isExists && isSigner ? user : null;
      }),
      isNil
    );
  }

  useEffect(() => {
    verifyEmployees();
  }, [isStateReady]);

  const data = useMemo(
    () => (!isAllReady ? [] : merge([], state.data, verified.data)),
    [isAllReady]
  );

  function onSelectSignersHandler(ids: number[]) {
    /* debug */ Logger.log(
      'SignCellView() onSelectSignersHandler([ ids ])',
      ids,
      props
    );
    reset();
    onSelect(file, ids);
  }

  const signers = map(file?.$signers, ({ id, fullname, position }) => {
    const isSigned = Boolean(file?.signers[id]);
    const isToMe = id === profile?.$id;
    const Icon = isSigned ? FileProtectOutlined : AuditOutlined;
    const isToMeAndNotSigned = isToMe && !isSigned;
    return (
      <Tooltip
        title={
          <ul className="signer-info">
            <li>
              <sub>{position}</sub>
            </li>
            <li>{fullname}</li>
          </ul>
        }
      >
        <Badge
          size="small"
          count={
            isToMeAndNotSigned ? (
              <ExclamationCircleFilled className="signer-badge" />
            ) : null
          }
        >
          <span data-signed={isSigned}>
            <Icon className={ClassNames(isToMeAndNotSigned ? 'to-sign' : '')} />
          </span>
        </Badge>
      </Tooltip>
    );
  });

  function onCancel() {
    reset && reset();
  }

  const selected = keys(file?.signers).map(id => +id);
  const disabled = filter(selected, id => Boolean(get(file?.signers, id))).map(
    id => +id
  );

  return (
    <div className="SignCellView">
      {!isReadOnly && !isLocalFile && isOwner && (
        <p className="add-signers" onClick={initialize}>
          {!isAllLoading ? <EditOutlined /> : <LoadingOutlined />}
          {t(`FilesExplorer.tooltips.addSigners`)}
        </p>
      )}
      <div className="signers">{signers}</div>
      {isAllReady && (
        <Modal
          closable={true}
          onCancel={onCancel}
          open={true}
          rootClassName="Modal"
          wrapClassName="dialog"
        >
          <span className="modal-header">
            <Header
              className
              text={t('CompanySigners.header')}
              size={Header.sizes.middle}
            />
            <Label className value={t('CompanySigners.description')} />
          </span>
          <CompanySigners
            disabled={disabled}
            selected={selected}
            data={data}
            onCancel={onCancel}
            onSelect={onSelectSignersHandler}
            onFilter={onSignersFilter}
          />
        </Modal>
      )}
    </div>
  );
}
