import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchLogFileThunk, fetchLogsThunk } from './logsOperations';
import { LogsState } from '../../types/intarfaces';

const initialState: LogsState = {
  files: [],
  filesLoading: false,
  filesError: null,

  logContent: '',
  logLoading: false,
  logError: null,
  selectedFile: '',
};

const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    clearLogContent: state => {
      state.logContent = '';
    },
    setSelectedFile: (state, action: PayloadAction<string>) => {
      state.selectedFile = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchLogsThunk.pending, state => {
      state.filesLoading = true;
      state.filesError = null;
    });
    builder.addCase(fetchLogsThunk.fulfilled, (state, action) => {
      state.filesLoading = false;
      state.files = action.payload;
    });
    builder.addCase(fetchLogsThunk.rejected, (state, action) => {
      state.filesLoading = false;
      state.filesError = String(action.payload || 'Error fetch logs');
    });

    builder.addCase(fetchLogFileThunk.pending, state => {
      state.logLoading = true;
      state.logError = null;
    });
    builder.addCase(fetchLogFileThunk.fulfilled, (state, action) => {
      state.logLoading = false;
      state.logContent = action.payload;
    });
    builder.addCase(fetchLogFileThunk.rejected, (state, action) => {
      state.logLoading = false;
      state.logError = String(action.payload || 'Error fetch log file');
    });
  },
});

export const { clearLogContent, setSelectedFile } = logsSlice.actions;
export default logsSlice.reducer;
