import React, { useEffect, useMemo } from 'react';
import useDepTickets from '../../../hooks/useDepTickets.hook';
import Spinner from '../../ui-kit/spinner';
import Message from '../../ui-kit/message';
import SupervisorReviewComponent from './SupervisorReviewComponent';
import { useReduxSelector } from '../../../hooks/redux.hook';
import useDepsHook from '../../../hooks/useDeps.hook';
import { useTranslation } from 'react-i18next';
import { PAGINATOR_DEFAULTS_PAGE_NO } from '../../../dto';
import Logger from '../../../helpers/logger';

export default function SupervisorReview(props) {
  /* debug */ Logger.info('SupervisorReview([ props ])', props);

  const { t } = useTranslation();
  const depsController = useDepsHook();
  const {
    tickets,
    profile: { data: profile },
  } = useReduxSelector(store => store);
  const deps = useReduxSelector(({ deps }) => deps);
  const { fetching, fetched, error, paginator, data } = tickets;
  const { load, setPageNo, setSearch, setPerPageLimit, setFilter, setSort } =
    useDepTickets();
  const pageNo = useMemo(() => paginator?.pageNo, [paginator?.pageNo]);
  const perPageLimit = useMemo(
    () => paginator?.perPageLimit,
    [paginator?.perPageLimit]
  );
  const sortBy = useMemo(() => paginator?.sortBy, [paginator?.sortBy]);
  const sortDirection = useMemo(
    () => paginator?.sortDirection,
    [paginator?.sortDirection]
  );
  const search = useMemo(() => tickets?.search, [tickets?.search]);
  const filter = useMemo(() => tickets?.filter, [tickets?.filter]);

  const isDepsInitialize = !deps?.fetching && deps?.fetched;
  const isTicketsInitialize = !fetching && fetched;
  const isInitialize = isDepsInitialize && isTicketsInitialize;

  async function refresh() {
    await load({ paginator, search, filter });
  }
  async function initialize() {
    // инициализация списков департаментов ;
    !isDepsInitialize && depsController.initialize();
  }

  function onMenuChangeHandler(item) {
    const review_status_id = item?.review_status_id;

    console.log('onMenuChangeHandler::review_status_id', review_status_id);

    setPageNo(PAGINATOR_DEFAULTS_PAGE_NO);
    setFilter({ ...(tickets.filter || {}), review_status_id });
  }
  function onPageSizeHandler(_, perPageLimit) {
    setPageNo(PAGINATOR_DEFAULTS_PAGE_NO);
    setPerPageLimit(perPageLimit);
  }
  function onPageHandler(pageNo) {
    setPageNo(pageNo - 1);
  }
  function onSearchHandler(search) {
    setSearch(search);
  }
  function onSortHandler(sortBy, sortDirection) {
    setSort(sortBy, sortDirection);
  }

  useEffect(() => {
    initialize();
  }, []);
  useEffect(() => {
    (async () => await refresh())();
  }, [filter, search, pageNo, perPageLimit, sortBy, sortDirection]);

  return (
    <Spinner spinVisible={!isInitialize} label={t('common.loading')}>
      {!error ? (
        isDepsInitialize && (
          <SupervisorReviewComponent
            fetching={fetching}
            data={data}
            filter={filter}
            search={search}
            paginator={paginator}
            onMenuChange={onMenuChangeHandler}
            onPage={onPageHandler}
            onPageSize={onPageSizeHandler}
            onSearch={onSearchHandler}
            onSort={onSortHandler}
          />
        )
      ) : (
        <Message
          className="ErrorMessage"
          type={Message.types.ERROR}
          header="ERROR"
          message={error.message}
        />
      )}
    </Spinner>
  );
}
