import React, { useState } from 'react';
import Header from '../ui-kit/header';
import Input from '../ui-kit/input';
import Button from '../ui-kit/button';
import PropTypes from 'prop-types';
import { forceChangeUserPassword } from '../../helpers/api.services';
import { isEmpty } from 'lodash';
import { NotificationManager } from 'react-notifications';
import { useReduxDispatch, useReduxSelector } from '../../hooks/redux.hook';
import { authenticationActions } from '../../store/slices/authentication.slice';
import '../../styles/components/login.scss';
import { useTranslation } from 'react-i18next';

function ChangePassword({ formData }) {
  const dispatch = useReduxDispatch();
  const { t } = useTranslation();

  const user = useReduxSelector(state => state.profile.data);

  const userId = user?.$id;

  const [data, setData] = useState(formData);

  const [fetch, setFetch] = useState(false);
  const [error, setError] = useState();
  const [validationError, setValidationError] = useState();
  const { destroy } = authenticationActions;

  const { oldPassword, newPassword } = data;

  const isSubmitEnabled =
    Boolean(
      !isEmpty(data?.oldPassword) &&
        !isEmpty(data?.newPassword) &&
        !isEmpty(data?.confirmPassword) &&
        data?.newPassword === data?.confirmPassword
    ) && !fetch;

  function onInputChangeHandler(id, value) {
    setData(state => ({
      ...state,
      [id]: value,
    }));

    if (id === 'newPassword' || id === 'confirmPassword') {
      const updatedData = { ...data, [id]: value };
      if (
        updatedData.newPassword &&
        updatedData.confirmPassword &&
        updatedData.newPassword !== updatedData.confirmPassword
      ) {
        setValidationError('Паролі не збігаються');
      } else {
        setValidationError(null);
      }
    }
  }

  async function onSubmitHandler() {
    setError(null);
    setValidationError(null);

    if (data.newPassword !== data.confirmPassword) {
      setValidationError('Паролі не збігаються');
      return;
    }

    setFetch(true);

    try {
      await forceChangeUserPassword(userId, {
        oldPassword,
        newPassword,
      });
      NotificationManager.info('Пароль успішно змінено');
      NotificationManager.warning('Увійдіть, використовуючи новий пароль');

      dispatch(destroy());
    } catch (exception) {
      !exception?.data &&
        exception?.message &&
        NotificationManager.error(exception.message);
      setError(exception);
    }

    setFetch(false);
  }

  return (
    <form className="Login">
      <Header
        text={t('ChangePasswordPage.header')}
        size={Header.sizes.middle}
      />
      <Input
        id="oldPassword"
        label={t('ChangePasswordPage.oldPassword.label')}
        value={data?.oldPassword}
        placeholder={t('ChangePasswordPage.oldPassword.placeholder')}
        type="password"
        onChange={onInputChangeHandler}
        error={error?.data?.oldPassword}
      />
      <Input
        id="newPassword"
        label={t('ChangePasswordPage.newPassword.label')}
        value={data?.newPassword}
        placeholder={t('ChangePasswordPage.newPassword.placeholder')}
        type="password"
        onChange={onInputChangeHandler}
        error={error?.data?.newPassword || validationError}
      />
      <Input
        id="confirmPassword"
        label={t('ChangePasswordPage.confirmPassword.label')}
        value={data?.confirmPassword}
        placeholder={t('ChangePasswordPage.confirmPassword.placeholder')}
        type="password"
        onChange={onInputChangeHandler}
        error={validationError}
      />
      <Button
        disabled={!isSubmitEnabled}
        className="sign-in"
        label={t('ChangePasswordPage.submitButton')}
        onClick={onSubmitHandler}
      />
    </form>
  );
}

ChangePassword.defaultProps = {
  formData: {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  },
};

ChangePassword.propTypes = {
  formData: PropTypes.shape({
    oldPassword: PropTypes.string,
    newPassword: PropTypes.string,
    confirmPassword: PropTypes.string,
  }),
};

export default ChangePassword;
