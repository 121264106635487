export const getRowClassByLogLevel = (logLevel?: string): string => {
  switch (logLevel) {
    case 'Error':
      return 'row-error';
    case 'Warning':
      return 'row-warning';
    case 'Information':
      return 'row-info';
    case 'Verbose':
      return 'row-verbose';
    case 'Debug':
      return 'row-debug';
    default:
      return '';
  }
};
