import { LANGUAGES_SUPPORTED, PERMISSIONS, UI } from '../../../dto';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { map, values } from 'lodash';
import useI18N from '../../../hooks/useI18N.hook';
import useSession from '../../../hooks/useSession.hook';
import { LogoutOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import Permission from '../permission';
import '../../../styles/components/ui-kit/menu.scss';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from '../../../hooks/redux.hook';
import Logger from '../../../helpers/logger';

function Logout({ onClick }) {
  const { t } = useTranslation();
  return (
    <div className="Logout" onClick={onClick}>
      <Tooltip placement="left" title={t('common.logout')}>
        <div className="icon">
          <LogoutOutlined />
        </div>
      </Tooltip>
    </div>
  );
}
Logout.defaultProps = {};
Logout.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function ContentLanguage({ onLogout }) {
  const { value: selected, changeLanguage } = useI18N();
  const supported = values(LANGUAGES_SUPPORTED);

  function getLangTitle(lang) {
    switch (lang) {
      case LANGUAGES_SUPPORTED.UA:
        return 'UA';
      case LANGUAGES_SUPPORTED.EN:
        return 'EN';
      default:
        return '?';
    }
  }

  function onSelectHandler(lang) {
    changeLanguage(lang);
  }
  //   function onLogoutHandler() {
  //       onLogout && onLogout() ;
  //   }
  function isActive(lang) {
    return lang === selected;
  }
  function createItems(items) {
    return map(items, (lang, index) => (
      <p
        key={index}
        className="lang"
        data-active={isActive(lang)}
        onClick={() => onSelectHandler(lang)}
      >
        {getLangTitle(lang)}
      </p>
    ));
  }

  const items = createItems(supported);

  return (
    <div className="ContentLanguage">
      <div className="wrapper">
        <div className="context">
          <div className="items">{items}</div>
        </div>
      </div>
    </div>
  );
}
ContentLanguage.defaultProps = {};
ContentLanguage.propTypes = {
  onLogout: PropTypes.func,
};

function ProfileHeader() {
  const { data: profile } = useReduxSelector(({ profile }) => profile);
  /* debug */ Logger.info('ProfileHeader([ profile ])', profile);
  return (
    <div className="ProfileHeader">
      <p key="pos">{profile?.position}</p>
      <b key="name">{profile?.fullname}</b>
    </div>
  );
}
ProfileHeader.defaultProps = {};
ProfileHeader.propTypes = {};

function Menu({ items }) {
  const { fromI18N } = useI18N();
  const location = useLocation();
  const { logout } = useSession(false);

  function isActive(path) {
    // return path === location.pathname.replaceAll( "/", "" ) ;
    return location.pathname.includes(path);
  }
  function createItems(items) {
    return map(items, ({ path, i18n }, index) => (
      <div key={index} className="MenuItem" data-active={isActive(path)}>
        <Link to={`/${path}`}>{fromI18N(i18n)}</Link>
      </div>
    ));
  }
  function onLogoutHandler() {
    logout('/');
  }

  const menuItems = createItems(items);

  return (
    <Permission.Verifycator
      key="update"
      id={UI.APP_TOP_MENU}
      name={PERMISSIONS.READ}
      value="1"
      whileFailed={null}
    >
      <div className="Menu">
        <div className="wrapper">{menuItems}</div>

        <Permission.Verifycator
          key="update"
          id={UI.LANG_SELECTOR}
          name={PERMISSIONS.READ}
          value="1"
          whileFailed={null}
        >
          <ContentLanguage onLogout={onLogoutHandler} />
        </Permission.Verifycator>

        <Permission.Verifycator
          key="profile-header"
          id={UI.PROFILE_HEADER}
          name={PERMISSIONS.READ}
          value="1"
          whileFailed={null}
        >
          <ProfileHeader />
        </Permission.Verifycator>

        <Permission.Verifycator
          key="logout"
          id={UI.LOGOUT_BUTTON}
          name={PERMISSIONS.READ}
          value="1"
          whileFailed={null}
        >
          <Logout onClick={onLogoutHandler} />
        </Permission.Verifycator>
      </div>
    </Permission.Verifycator>
  );
}
Menu.defaultProps = {
  items: [],
};
Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

export default Menu;
