import { useReduxSelector } from './redux.hook';
import { find, forEach, get, keys } from 'lodash';
import { getCSSVariableValue, setCSSVariableValue } from '../helpers/common';

function applyVariables(properties: object) {
  if (!properties) return;
  forEach(keys(properties), (name: string) => {
    if (name.search('--css') !== -1)
      setCSSVariableValue(name, get(properties, name));
  });
}
function antdCreateColorThemeConfig() {
  const cssDefaultPrimaryBackgroundColor = getCSSVariableValue(
    '--cssDefaultPrimaryBackgroundColor'
  );
  const cssDefaultBorderColor = getCSSVariableValue(
    '--cssDefaultBorderColor_25'
  );

  // node_modules/antd/es/theme/interface/seeds.d.ts

  return {
    token: {
      colorPrimary: cssDefaultPrimaryBackgroundColor,
      colorLink: cssDefaultPrimaryBackgroundColor,
      borderRadius: 0,
      colorBorder: cssDefaultBorderColor,
      colorBorderSecondary: cssDefaultBorderColor,
      //  paddingXS: 4,
      //  paddingSM: 4,
      //  padding: 4,
      //  paddingXXS: 4
    },
    components: {
      Input: {
        colorPrimary: cssDefaultPrimaryBackgroundColor,
      },
      Table: {
        headerSplitColor: cssDefaultBorderColor,
        colorSplit: cssDefaultBorderColor,
      },
    },
  };
}

export type TPermissionsHook = {
  initialize: Function;
  getPermissionsById: Function;
};
export default function usePermissionsHook(): TPermissionsHook {
  const {
    permissions: { data: permissions },
  } = useReduxSelector(({ permissions }) => ({ permissions }));

  function initialize(permission_id: string): object {
    const rule: any = find(permissions, { ui_section_id: permission_id });
    const options: any = rule?.json;
    applyVariables(options);
    return antdCreateColorThemeConfig();
  }
  function getPermissionsById(id: string): any {
    const p: any = find(permissions, { ui_section_id: id });
    return p?.permissions;
  }

  return { initialize, getPermissionsById };
}
