import { useEffect, useState } from 'react';
import {
  getCompanyByID,
  searchCompanyClients,
} from '../../../helpers/api.services';

export function CompanyClientsRenderer({ companyId, placeholder }) {
  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (companyId) {
      setLoading(true);
      getCompanyByID(companyId)
        .then(result => {
          setData(result);
        })
        .catch(error => {
          console.error('Failed to fetch company clients: ', error);
          setData([]);
        })
        .finally(() => setLoading(false));
    } else {
      setData([]);
    }
  }, [companyId]);

  if (!companyId) {
    return <b>{placeholder}</b>;
  }

  if (loading) {
    return <span>Загрузка...</span>;
  }

  if (!data?.data?.length) {
    return <b>{placeholder}</b>;
  }

  return (
    <>
      {data.data.map(client => (
        <b key={client.id}>{client.name}</b>
      ))}
    </>
  );
}
