export enum ROLES {
  /**
   * @description
   * Идентификатор роли, которая будет назначаться "по умолчанию", если=
   * 1. у пользователя нет прав ;
   * 2. пользователь не имеет ключа сессии ;
   * 3. любой другой (анонимный) пользователь ;
   */
  ANY = '*',
  /**
   * @description
   * Только что зарегестрированный пользователь,
   * Пользователь - ожидающий назначение прав.
   */
  GUEST = 'GUEST',
  /**
   * @description
   * клиент системы ;
   */
  CLIENT = 'CLIENT',
  /**
   * @description
   * оператор одного из департаментов
   */
  OPERATOR = 'OPERATOR',
  SUPERVISOR = 'SUPERVISOR',
  /**  "Офицер безопасности"*/
  SECURITY_OFFICER = 'SECURITY_OFFICER',
}
export enum RULES {
  ALLOW = 'ALLOW',
  DENIED = 'DENIED',
}
export enum LANGUAGES_SUPPORTED {
  EN = 'en_US',
  UA = 'uk_UA',
}
export enum PERMISSIONS {
  READ = 'read',
  UPDATE = 'update',
  CREATE = 'create',
  DELETE = 'delete',
}
export enum UI {
  MY_TICKETS = 'MyTickets',
  PROFILE = 'Profile',
  CREATE_NEW_TICKET = 'CreateNewTicket',
  TICKET_PROPERTY = 'TicketProperty',
  DEP_TICKETS = 'DepTickets',
  FILE_STATUS = 'FileStatus',
  SUPERVISOR_REVIEW = 'SupervisorReview',
  TICKET_VERIFICATION_STATUS = 'TicketVerificationStatus',
  LANG_SELECTOR = 'LangSelector',
  LOGOUT_BUTTON = 'LogoutButton',
  USERS = 'UsersManager',
  ENDPOINTS = 'EndPointsManager',
  LOGS = 'LogsExplorer',
  USERS_ACTIONS_TOOLBAR = 'UsersActionsToolbar',
  CHANGE_PASSWORD_BUTTON = 'ChangePasswordButton',
  PROFILE_HEADER = 'ProfileHeader',
  APP_TOP_MENU = 'ApplicationTopMenu',
}
export enum ENDPOINTS {
  /** @todo В перспективе эти end-point`ы нужно описать в API запросах */
  DLL = 'DLL',
  HOME = 'HOME',
  PROFILE = 'PROFILE',
  REFRESH = 'REFRESH',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  TICKET_BY_ID = 'TICKET_BY_ID',
  TICKET_CREATE = 'TICKET_CREATE',
  TICKETS_MY = 'TICKETS_MY',
  TICKET_UPDATE_BY_ID = 'TICKET_UPDATE_BY_ID',
  TICKET_REMOVE_BY_ID = 'TICKET_REMOVE_BY_ID',
}
export enum SORT_DIRECTIONS {
  ASC = 'asc',
  DESC = 'desc',
}
export enum TICKET_STATUSES {
  PROCESSED = 'PROCESSED',
  REJECTED = 'REJECTED',
  SIGNATURE_PENDING = 'SIGNATURE_PENDING',
  SUCCESS = 'SUCCESS',
  DRAFT = 'DRAFT',
  NEW = 'NEW',
  DEP_REQUEST = 'DEP_REQUEST',
  WAIT_FOR_UPDATE = 'WAIT_FOR_UPDATE',
}
export enum FILES_STATUSES {
  REJECTED = 'FS_REJECTED',
  APPROVED = 'FS_APPROVED',
  PENDING = 'FS_PENDING',
  ONJOB = 'FS_ONJOB',
}
export enum READY_STATES {
  NOT_INITIALIZED = 0,
  SENT_TO_DEPS = 1,
  SENT_TO_COMPANY = 2,
}
export enum REVIEW_STATUSES {
  RV_ON_REVIEW = 'RV_ON_REVIEW',
  RV_APPROVED = 'RV_APPROVED',
  RV_REJECTED = 'RV_REJECTED',
}
export enum IFRAME_EVENTS {
  GET_COMPANY_EMPLOYERS = 'get.company.employers',
  GET_COMPANY_SIGNERS = 'get.company.signers',
  GET_MY_ACTIVE_COMPANY = 'get.my.active.company',
  SIGN_VERIFICATION = 'sign.verification',
}
export enum SIGN_RIGHTS {
  ALL = 0,
  PRIMARY = 1,
  SECONDARY = 2,
  DISABLED = 3,
}
export enum HTTP_X_HEADERS {
  FILE_MIMETYPE = 'x-file-metadata-mimetype',
  FILE_NAME = 'x-file-metadata-name',
}

export const RV_UNVERIFIED = 'RV_UNVERIFIED';
export const PAGINATOR_DEFAULTS_PAGE_NO: number = 0;
export const PAGINATOR_DEFAULTS_PER_PAGE_LIMIT: number = 15;
