import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AG_GRID_LOCALE_UA } from '@ag-grid-community/locale';
import classNames from 'classnames';

import { getSettings } from '../../../../data/settings';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { setSelectedFile } from '../../../../store/logs/logsSlice';
import { fetchLogsThunk } from '../../../../store/logs/logsOperations';
import {
  selectLogFiles,
  selectLogFilesLoading,
} from '../../../../store/logs/logsSelectors';

import Spinner from '../../../ui-kit/spinner';
import { QuickFilter } from '../QuickFilter';
import { getLogsColumns } from './logsColumns';

export const LogsTable = () => {
  const dispatch = useAppDispatch();
  const files = useAppSelector(selectLogFiles);
  const filesLoading = useAppSelector(selectLogFilesLoading);

  const [quickFilter, setQuickFilter] = useState('');
  const gridApiRef = useRef<GridApi | null>(null);
  const settings = getSettings();
  const localeText = AG_GRID_LOCALE_UA;
  const ensureDomOrder = true;
  const { t } = useTranslation();

  const columnDefs = useMemo(() => getLogsColumns(t), [t]);

  useEffect(() => {
    dispatch(fetchLogsThunk());
  }, [dispatch]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridApiRef.current = params.api;
  }, []);

  const onRowClicked = useCallback(
    (params: any) => {
      if (params.data?.filename) {
        dispatch(setSelectedFile(params.data.filename));
      }
    },
    [dispatch]
  );

  return (
    <div>
      {filesLoading ? (
        <Spinner label="Завантаження файлів логів..." />
      ) : (
        <>
          <QuickFilter
            label={`${t('Logs.labelLogs')}: `}
            gridApiRef={gridApiRef}
            value={quickFilter}
            setValue={setQuickFilter}
            placeholder={t('Logs.quickSearchLogsPl')}
          />

          <div className={classNames('ag-theme-quartz', 'LogsTable')}>
            <AgGridReact
              ensureDomOrder={ensureDomOrder}
              localeText={localeText}
              columnDefs={columnDefs}
              rowData={files}
              onGridReady={onGridReady}
              onRowClicked={onRowClicked}
              pagination
              paginationPageSize={20}
              quickFilterText={quickFilter}
              context={{ apiHostUrl: settings.apiHostUrl }}
              enableCellTextSelection={true}
            />
          </div>
        </>
      )}
    </div>
  );
};
