import React, { FC, useCallback } from 'react';
import { GridApi } from 'ag-grid-community';

interface ColumnVisibilityTogglerProps {
  columns: { colId: string; label: string }[];
  visibleCols: Record<string, boolean>;
  setVisibleCols: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  gridApiRef: React.MutableRefObject<GridApi | null>;
}

export const ColumnVisibilityToggler: FC<ColumnVisibilityTogglerProps> = ({
  columns,
  visibleCols,
  setVisibleCols,
  gridApiRef,
}) => {
  const handleToggleColumn = useCallback(
    (colId: string) => {
      setVisibleCols(prev => {
        const newVal = !prev[colId];
        const updated = { ...prev, [colId]: newVal };

        if (gridApiRef.current) {
          gridApiRef.current.setColumnsVisible([colId], newVal);
        }
        return updated;
      });
    },
    [gridApiRef, setVisibleCols]
  );

  return (
    <>
      <fieldset
        aria-labelledby="columnVisibilityLegend"
        style={{
          display: 'inline-block',
          border: 'none',
        }}
      >
        <legend
          id="columnVisibilityLegend"
          style={{
            display: 'none',
            border: 'none',
          }}
        >
          Toggle column visibility
        </legend>
        {columns.map(({ colId, label }) => {
          const checkboxId = `toggle-${colId}`;
          return (
            <div
              key={colId}
              style={{ display: 'inline-block', marginRight: '12px' }}
            >
              <input
                type="checkbox"
                id={checkboxId}
                checked={visibleCols[colId] ?? true}
                onChange={() => handleToggleColumn(colId)}
                aria-label={`Toggle visibility for column ${label}`}
              />

              <label htmlFor={checkboxId}>{label}</label>
            </div>
          );
        })}
      </fieldset>
    </>
  );
};
