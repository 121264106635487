import React, { useEffect, useMemo, useState } from 'react';
import useMyTickets from '../../../hooks/useMyTickets.hook';
import Spinner from '../../ui-kit/spinner';
import Button from '../../ui-kit/button';
import Message from '../../ui-kit/message';
import { useReduxSelector } from '../../../hooks/redux.hook';
import MyTicketsComponent from './MyTicketsComponent';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PAGINATOR_DEFAULTS_PAGE_NO } from '../../../dto';
import useDepsHook from '../../../hooks/useDeps.hook';
import { Modal } from 'antd';

export default function MyTickets() {
  const { t } = useTranslation();
  const [recycleBin, addToRecycleBin] = useState();
  const { initialize: depsInit } = useDepsHook();
  const tickets = useReduxSelector(({ tickets }) => tickets);
  const deps = useReduxSelector(({ deps }) => deps);
  const { fetching, fetched, error, paginator, data } = tickets;
  const {
    load,
    setPageNo,
    setSearch,
    setPerPageLimit,
    setFilter,
    setSort,
    removeTicketByID,
  } = useMyTickets();
  const pageNo = useMemo(() => paginator?.pageNo, [paginator?.pageNo]);
  const perPageLimit = useMemo(
    () => paginator?.perPageLimit,
    [paginator?.perPageLimit]
  );
  const sortBy = useMemo(() => paginator?.sortBy, [paginator?.sortBy]);
  const sortDirection = useMemo(
    () => paginator?.sortDirection,
    [paginator?.sortDirection]
  );
  const search = useMemo(() => tickets?.search, [tickets?.search]);
  const filter = useMemo(() => tickets?.filter, [tickets?.filter]);
  const isDepsInitialize = !deps?.fetching && deps?.fetched;
  const isTicketsInitialize = !fetching && fetched;
  const isInitialize = isDepsInitialize && isTicketsInitialize;
  const isRecycleBinNotEmpty = !isNil(recycleBin);

  async function refresh() {
    await load({ paginator, search, filter });
  }
  async function initialize() {
    // инициализация списков департаментов ;
    !isDepsInitialize && depsInit();
  }
  async function removeTicket() {
    const ticket_id = recycleBin?.id;
    await removeTicketByID(ticket_id);
    clearRecycleBin();
  }
  function clearRecycleBin() {
    addToRecycleBin(null);
  }
  function showDeleteFileConfirmation() {
    if (isRecycleBinNotEmpty) {
      function onYes(handler) {
        removeTicket();
        handler.destroy();
      }
      function onClose(handler) {
        clearRecycleBin();
        handler.destroy();
      }
      const handler = Modal.confirm({
        title: t('common.confirmation'),
        content: t('prompts.removeTicket', recycleBin),
        footer: (
          <span className="ant-modal-footer">
            <Button
              key="yes"
              className="save"
              label={t('common.yes')}
              onClick={() => onYes(handler)}
            />
            <Button
              key="cancel"
              ghost
              className="cancel"
              label={t('common.cancel')}
              onClick={() => onClose(handler)}
            />
          </span>
        ),
      });
    }
  }

  function onMenuChangeHandler(item) {
    const status_id = item?.status_id;
    setPageNo(PAGINATOR_DEFAULTS_PAGE_NO);
    setFilter({ ...(tickets.filter || {}), status_id });
  }
  function onPageSizeHandler(_, perPageLimit) {
    setPageNo(PAGINATOR_DEFAULTS_PAGE_NO);
    setPerPageLimit(perPageLimit);
  }
  function onPageHandler(pageNo) {
    setPageNo(pageNo - 1);
  }
  function onSearchHandler(search) {
    setSearch(search);
  }
  function onSortHandler(sortBy, sortDirection) {
    setSort(sortBy, sortDirection);
  }
  function onRemoveHandler(ticket) {
    addToRecycleBin(ticket);
  }

  useEffect(() => {
    initialize();
  }, []);
  useEffect(() => {
    (async () => await refresh())();
  }, [filter, search, pageNo, perPageLimit, sortBy, sortDirection]);
  useEffect(() => {
    showDeleteFileConfirmation();
  }, [isRecycleBinNotEmpty]);

  return (
    <Spinner spinVisible={!isInitialize} label={t('common.loading')}>
      {!error ? (
        isDepsInitialize && (
          <MyTicketsComponent
            fetching={fetching}
            data={data}
            filter={filter}
            search={search}
            paginator={paginator}
            onMenuChange={onMenuChangeHandler}
            onPage={onPageHandler}
            onPageSize={onPageSizeHandler}
            onSearch={onSearchHandler}
            onSort={onSortHandler}
            onRemove={onRemoveHandler}
          />
        )
      ) : (
        <Message
          className="ErrorMessage"
          type={Message.types.ERROR}
          header="ERROR"
          message={error.message}
        />
      )}
    </Spinner>
  );
}
