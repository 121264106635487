import { find, get } from 'lodash';
import { jsonToObject, shortenString } from '../../../helpers/common';
import { EyeFilled } from '@ant-design/icons';
import { Profile_I } from '../../../types/intarfaces';
import Moments from 'moment';
import { Tooltip } from 'antd';
import {
  DATE_TIME_FORMAT,
  TicketPropertyViewModes,
} from '../../../data/constants';
import React from 'react';
import StatusLabel from '../../ui-kit/status/label';
import { UI } from '../../../dto';
import { Link } from 'react-router-dom';
import ReviewStatusIdCellRenderer from '../dep_tickets/ReviewStatusId.CellRenderer';
import { CompanyClientsRenderer } from '../dep_tickets/CompanyClientsRenderer';

export enum SupervisorReviewColumns {
  id = 'id',
  status_id = 'status_id',
  owner_id = 'owner_id',
  subject = 'subject',
  description = 'description',
  dep_id = 'dep_id',
  company_id = 'company_id',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  userId = 'user_id',
  review_status_id = 'review_status_id',
  review_status_i18n = 'review_status_i18n',
}
export type TCellRendererWrapperProps = {
  id: string;
  value: string;
  key: number;
  data: any;
  index: number;
  children?: any;
};
export function CellRendererWrapper(props: TCellRendererWrapperProps) {
  return (
    <span key={`${props.id}-${props.index}`} data-field-id={props.id}>
      {props?.children}
    </span>
  );
}

type TSupervisorReviewCellRendererParams = {
  t: Function;
  i18n: string;
  deps: any[];
  fromI18N: Function;
  permissions: any[];
  profile: Profile_I;
};
export default (params: TSupervisorReviewCellRendererParams) => {
  const { t, i18n, deps, fromI18N, permissions, profile } = params;
  return {
    [SupervisorReviewColumns.review_status_id]: (
      params: TCellRendererWrapperProps
    ): any => {
      return (
        <ReviewStatusIdCellRenderer
          {...params}
          i18n={i18n}
          t={t}
          profile={profile}
        />
      );
    },
    [SupervisorReviewColumns.id]: (params: TCellRendererWrapperProps): any => {
      const isFavoriteTask = params?.data?.is_favorite;
      return (
        <CellRendererWrapper {...params}>
          <b data-value={params.value} data-owner={!isFavoriteTask}>
            {params.value.toString().trim()}
          </b>
        </CellRendererWrapper>
      );
    },
    [SupervisorReviewColumns.status_id]: (
      params: TCellRendererWrapperProps
    ): any => {
      const status = t(`StatusSelector.statuses.${params?.data?.status_id}`);
      return (
        <CellRendererWrapper {...params}>
          <StatusLabel id={params.value}>{status}</StatusLabel>
        </CellRendererWrapper>
      );
    },
    [SupervisorReviewColumns.owner_id]: (
      params: TCellRendererWrapperProps
    ): any => {
      const depName = `${
        params?.data?.$company?.name ||
        fromI18N(jsonToObject(profile.dep_title as string))
      }`.toUpperCase();
      return (
        <CellRendererWrapper {...params}>
          <b>
            <p className="position">
              {params?.data?.$owner?.position ||
                t('errors.userPositionIsUndeclared')}
            </p>
            <br />
            {depName}
          </b>
          {params?.data?.$owner?.fullname}
        </CellRendererWrapper>
      );
    },
    [SupervisorReviewColumns.dep_id]: (
      params: TCellRendererWrapperProps
    ): any => {
      const id = params.value;
      const isSameUser = params?.data?.$owner?.id === params?.data?.$user?.id;

      if (isSameUser) {
        return (
          <CellRendererWrapper {...params}>
            <CompanyClientsRenderer
              companyId={params.data.company_id}
              placeholder="Немає даних"
            />
          </CellRendererWrapper>
        );
      }
      const item: any = find(deps, { id });
      if (!item) return null;

      const title = get(jsonToObject(item?.title), i18n, null);
      const description = get(jsonToObject(item?.description), i18n, null);

      return (
        <CellRendererWrapper {...params}>
          <Tooltip placement="top" title={description}>
            <b>{title}</b>
          </Tooltip>
        </CellRendererWrapper>
      );
    },
    [SupervisorReviewColumns.subject]: (
      params: TCellRendererWrapperProps
    ): any => {
      const limit = 24;
      const value = `${params?.value || ''}`.trim();
      const cut = shortenString(value, limit, '...');
      const isLengthChanged = cut.length < value.length;

      return (
        <CellRendererWrapper {...params}>
          {!isLengthChanged ? (
            cut
          ) : (
            <Tooltip placement="top" title={value}>
              <p>{cut}</p>
            </Tooltip>
          )}
        </CellRendererWrapper>
      );
    },
    [SupervisorReviewColumns.description]: (
      params: TCellRendererWrapperProps
    ): any => {
      const limit = 24;
      const value = `${params?.value || ''}`.trim();
      const cut = shortenString(value, limit, '...');
      const isLengthChanged = cut.length < value.length;

      return (
        <CellRendererWrapper {...params}>
          {!isLengthChanged ? (
            cut
          ) : (
            <Tooltip placement="top" title={value}>
              <p>{cut}</p>
            </Tooltip>
          )}
        </CellRendererWrapper>
      );
    },
    [SupervisorReviewColumns.createdAt]: (
      params: TCellRendererWrapperProps
    ): any => {
      return (
        <CellRendererWrapper {...params}>
          <p>{Moments(params?.data?.createdAt).format(DATE_TIME_FORMAT)}</p>
        </CellRendererWrapper>
      );
    },
    [SupervisorReviewColumns.updatedAt]: (
      params: TCellRendererWrapperProps
    ): any => {
      return (
        <CellRendererWrapper {...params}>
          <p>{Moments(params?.data?.updatedAt).format(DATE_TIME_FORMAT)}</p>
        </CellRendererWrapper>
      );
    },
    [SupervisorReviewColumns.userId]: (
      params: TCellRendererWrapperProps
    ): any => {
      const user = params?.data?.$user;
      return (
        <CellRendererWrapper {...params}>
          {user && (
            <b>
              <p className="position">
                {user?.position || t('errors.userPositionIsUndeclared')}
              </p>
              <br />
              {user?.fullname}
            </b>
          )}
        </CellRendererWrapper>
      );
    },
    ['$toolbar']: (params: TCellRendererWrapperProps): any => {
      const getRoutePath = get(
        find(permissions, { ui_section_id: UI.TICKET_PROPERTY }),
        'path',
        ''
      ).replace(':ticket_id', params?.data?.id);
      return (
        <CellRendererWrapper {...params}>
          <div data-value>
            <Tooltip key="view" placement="top" title={t('common.view')}>
              <span>
                <Link
                  to={`/${getRoutePath}`}
                  state={{ mode: TicketPropertyViewModes.ReadOnly }}
                >
                  <EyeFilled key="reassign" className="button-ticket-view" />
                </Link>
              </span>
            </Tooltip>
          </div>
        </CellRendererWrapper>
      );
    },
  };
};
