import { ColDef } from 'ag-grid-community';
import { formatDate } from '../../../../../helpers/common';

export const getLogViewerColumns = (): ColDef[] => [
  {
    colId: 'timestamp',
    headerName: 'Time',
    field: 'timestamp',
    width: 200,
    sortable: true,
    filter: 'agTextColumnFilter',
    valueFormatter: params => formatDate(params.value),
  },
  {
    colId: 'logLevel',
    headerName: 'Level',
    field: 'logLevel',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    colId: 'message',
    headerName: 'Message',
    field: 'message',
    flex: 2,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    colId: 'userFullName',
    headerName: 'User',
    field: 'userFullName',
    width: 220,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    colId: 'userPosition',
    headerName: 'Position',
    field: 'userPosition',
    width: 150,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    colId: 'ticketId',
    headerName: 'Ticket ID',
    field: 'ticketId',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    colId: 'companyName',
    headerName: 'Company Name',
    field: 'companyName',
    width: 200,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    colId: 'fileName',
    headerName: 'File Name',
    field: 'fileName',
    width: 220,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    colId: 'severity',
    headerName: 'Severity',
    field: 'severity',
    width: 150,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
];
