export const getSelectableColumns = () => [
  { colId: 'timestamp', label: 'Time' },
  { colId: 'logLevel', label: 'Level' },
  { colId: 'message', label: 'Message' },
  { colId: 'userFullName', label: 'User' },
  { colId: 'userPosition', label: 'Position' },
  { colId: 'ticketId', label: 'Ticket ID' },
  { colId: 'companyName', label: 'Company Name' },
  { colId: 'fileName', label: 'File Name' },
  { colId: 'severity', label: 'Severity' },
];
